import { S } from '@/app/utilities';
import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ParameterComponent } from '../components/task-configuration/parameter-component.constants';
import { OtherInputParameterType } from '../constants';
import { InputParameter, Workflow } from '../types';
import { Variable } from '@/app/interfaces';

export function useTaskParameterConfiguration(
    parameter: InputParameter,
    workflow: Ref<Workflow>,
    parametersDefinition: Ref<Record<string, InputParameter>>,
    visible: Ref<boolean>,
    forceUpdate: Ref<any>,
    availableVariables: Ref<Record<string, Variable>>,
    isInLoop: Ref<boolean>,
    inputParameterValue: Ref<any>,
) {
    const name = computed(() => {
        const trimmedName = parameter.name.split('_').join(' ');

        return trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);
    });

    const parameterComponent = computed(() => ParameterComponent.find(parameter.category, parameter.type));

    // Custom validation messages (info icon)
    const validationMessages = computed(() => parameter.validationMessages);

    // Computes a list of rules to check the selected value by
    const rules = computed(() => {
        const ruleList: any = {};
        if (parameter.required) {
            ruleList.required = true;
        }

        if (isInLoop.value && Object.keys(availableVariables.value).length > 0) {
            ruleList.variableAware = JSON.stringify({
                availableVariables: availableVariables.value,
                value: inputParameterValue.value,
            });
        }

        if (
            !R.isNil(parameter.validation) &&
            S.has('regex', parameter.validation) &&
            !R.isNil(parameter.validation.regex) &&
            !R.isEmpty(parameter.validation.regex)
        ) {
            ruleList.regex = new RegExp(parameter.validation.regex);
        }

        // Adds required if requiredIf is defined in validation
        if (
            !R.isNil(parameter.validation) &&
            S.has('requiredIf', parameter.validation) &&
            !R.isNil(parameter.validation.requiredIf) &&
            !R.isEmpty(parameter.validation.requiredIf)
        ) {
            const splitResult: string[] = parameter.validation.requiredIf.split(',');
            const path = [R.trim(splitResult.shift() as string), 'value'];
            if (
                R.hasPath(path, workflow.value) &&
                !R.isNil(R.path(path, workflow.value)) &&
                !R.isEmpty(R.path(path, workflow.value))
            ) {
                // if requiredIf path is found in the current configuration
                const currentValue = R.path(path, workflow.value);
                if (R.is(Array, currentValue)) {
                    // if currentValue is an array then check all possible values of array
                    // if any match then set to required true
                    for (let v = 0; v < (currentValue as any[]).length; v++) {
                        const val = (currentValue as any[])[v];
                        if (splitResult.map((s: string) => s.trim()).includes(String(val))) {
                            ruleList.required = true;
                        }
                    }
                } else if (splitResult.map((s: string) => s.trim()).includes(String(currentValue))) {
                    // if not an array and value is included in expected required
                    // if values then set required to true
                    ruleList.required = true;
                } else {
                    ruleList.required = parameter.required;
                }
            } else {
                // if requiredIf path does not exist then we reset the required
                // to the required parameter itself
                ruleList.required = parameter.required;
            }
        }

        if (
            !R.isNil(parameter.validation) &&
            S.has('length', parameter.validation) &&
            !R.isNil(parameter.validation.length) &&
            !R.isEmpty(parameter.validation.length) &&
            S.has('min', parameter.validation.length) &&
            !R.isNil(parameter.validation.length.min) &&
            !R.isEmpty(parameter.validation.length.min)
        ) {
            if (parameter.multiple) {
                ruleList.minList = parameter.validation.length.min;
            } else {
                ruleList.min = parameter.validation.length.min;
            }
        }

        if (
            !R.isNil(parameter.validation) &&
            S.has('length', parameter.validation) &&
            !R.isNil(parameter.validation.length) &&
            !R.isEmpty(parameter.validation.length) &&
            S.has('max', parameter.validation.length) &&
            !R.isNil(parameter.validation.length.max) &&
            !R.isEmpty(parameter.validation.length.max)
        ) {
            if (parameter.multiple) {
                ruleList.maxList = parameter.validation.length.max;
            } else {
                ruleList.max = parameter.validation.length.max;
            }
        }

        if (
            !R.isNil(parameter.validation) &&
            S.has('range', parameter.validation) &&
            !R.isNil(parameter.validation.range) &&
            !R.isEmpty(parameter.validation.range) &&
            S.has('min', parameter.validation.range) &&
            !R.isNil(parameter.validation.range.min) &&
            !R.isEmpty(parameter.validation.range.min)
        ) {
            ruleList.min_value = parameter.validation.range.min;
        }

        if (
            !R.isNil(parameter.validation) &&
            S.has('range', parameter.validation) &&
            !R.isNil(parameter.validation.range) &&
            !R.isEmpty(parameter.validation.range) &&
            S.has('max', parameter.validation.range) &&
            !R.isNil(parameter.validation.range.max) &&
            !R.isEmpty(parameter.validation.range.max)
        ) {
            ruleList.max_value = parameter.validation.range.max;
        }

        return ruleList;
    });

    const display = computed(() => {
        if (!visible.value) return false;
        if (parameterComponent.value.isHidden()) return false;

        const checks: { parameter: string; values: any[] }[] = [];

        if (
            !R.isNil(parameter.validation) &&
            S.has('displayIf', parameter.validation) &&
            !R.isNil(parameter.validation.displayIf) &&
            !R.isEmpty(parameter.validation.displayIf)
        ) {
            const displayIfParameterValues = parameter.validation.displayIf.split(',');

            if (displayIfParameterValues.length > 0) {
                const parameterToCheck = R.trim(displayIfParameterValues.shift() as string);
                const parameterToCheckComponent = S.has(parameterToCheck, parametersDefinition.value)
                    ? ParameterComponent.find(
                          parametersDefinition.value[parameterToCheck].category,
                          parametersDefinition.value[parameterToCheck].type,
                      )
                    : null;
                checks.push({
                    parameter: parameterToCheck,
                    values: R.clone(displayIfParameterValues).map((v: any) =>
                        parameterToCheckComponent ? parameterToCheckComponent.processDisplayIf(v.trim()) : v.trim(),
                    ),
                });
            }
        }

        if (
            !R.isNil(parameter.validation) &&
            forceUpdate.value &&
            parameter.type === OtherInputParameterType.Dynamic &&
            parameter.validation.dynamic
        ) {
            checks.push({
                parameter: parameter.validation.dynamic,
                values: [],
            });
        }

        for (let c = 0; c < checks.length; c++) {
            const check: { parameter: string; values: any[] } = checks[c];
            if (!R.isNil(check.parameter)) {
                // If the parameter is not defined at all
                if (
                    !S.has(check.parameter, workflow.value) ||
                    R.isNil(workflow.value[check.parameter]) ||
                    R.isEmpty(workflow.value[check.parameter]) ||
                    R.isNil(workflow.value[check.parameter].value) ||
                    R.isEmpty(workflow.value[check.parameter].value)
                ) {
                    return false;
                }

                const parameterValue = workflow.value[check.parameter].value;
                if (!R.isNil(check.values) && !R.isEmpty(check.values)) {
                    // If the parameter value is define and we are looking for specific values
                    if (R.is(Array, parameterValue)) {
                        // In case the parameter is an array
                        for (let p = 0; p < parameterValue.length; p++) {
                            const param = parameterValue[p];
                            if (check.values.includes(param)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    if (!R.is(Array, parameterValue) && !check.values.includes(parameterValue)) {
                        // In case the parameter is not an array
                        return false;
                    }
                }
            }
        }
        return true;
    });

    return { name, parameterComponent, rules, display, validationMessages };
}
