import { S } from '@/app/utilities';
import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import {
    BlockCategory,
    BlockCategoryWrapper,
    BlockOutputType,
    BlockType,
    InputParameterCategory,
    OtherInputParameterType,
} from '../constants';
import { InputParameter, Task } from '../types';

export function useTasks(tasks: Ref<Map<string, Task>>) {
    const block = (task: Task) => task.block;

    const blockCategory = (task: Task) => BlockCategoryWrapper.find(task.block.category as BlockCategory);

    const blockType = (task: Task) => {
        if (block(task).type) {
            return block(task).type;
        }
        return null;
    };

    const dataframeParameters = (task: Task) =>
        block(task).parameters.filter(
            (parameter: InputParameter) => parameter.category === InputParameterCategory.Dataframe,
        );

    const parameters = (task: Task) =>
        block(task).parameters.filter(
            (parameter: InputParameter) => parameter.category !== InputParameterCategory.Dataframe,
        );

    const dataframeParameterNames = (task: Task): string[] =>
        dataframeParameters(task).reduce((acc: string[], param: InputParameter) => {
            acc.push(param.name);
            return acc;
        }, []);

    const assetParameterName = (task: Task): string | null =>
        block(task).parameters.find(
            (parameter: InputParameter) =>
                parameter.category === InputParameterCategory.Asset &&
                [OtherInputParameterType.Dataset, OtherInputParameterType.Result].includes(parameter.type as any),
        )?.name;

    const dataframesFromTask = (task: Task) => R.pick(dataframeParameterNames(task), task.configuration);

    const availableDataframes = (task: Task) =>
        R.sort(
            (a: Task, b: Task) => a.displayName.localeCompare(b.displayName),
            [...tasks.value.values()].filter(
                (t: Task) =>
                    t.block.category !== BlockCategory.Output && t.block.type !== BlockType.Train && t.id !== task.id,
            ),
        );

    const dataframesForTask = (task: Task) =>
        task.executions.length > 0 && task.executions[0].result?.structure ? task.executions[0].result.structure : null;

    const dataframeColumnsForTask = (task: Task): any => {
        if (task.block.output.type === BlockOutputType.Dynamic || task.block.output.type === BlockOutputType.None) {
            return dataframesForTask(task);
        }
        if (task.block.output.type === BlockOutputType.Static) {
            return task.block.output[BlockOutputType.Static];
        }

        if (
            task.block.output.type === BlockOutputType.Input &&
            S.has(task.block.output[BlockOutputType.Input], dataframesFromTask(task)) &&
            S.has('task', dataframesFromTask(task)[task.block.output[BlockOutputType.Input]]) &&
            tasks.value.has(dataframesFromTask(task)[task.block.output[BlockOutputType.Input]].task)
        ) {
            const innerTask = tasks.value.get(dataframesFromTask(task)[task.block.output[BlockOutputType.Input]].task);
            if (!R.isNil(innerTask)) {
                return dataframeColumnsForTask(innerTask);
            }
        }
        return null;
    };

    const columnsPerTask = computed(() => {
        return [...tasks.value.values()].reduce((acc: any, t: Task) => {
            acc[t.id] = dataframeColumnsForTask(t);
            return acc;
        }, {});
    });

    const getTask = (taskId: string) => {
        if (tasks.value.has(taskId)) {
            return tasks.value.get(taskId);
        }
        return null;
    };

    const description = (task: Task) => {
        let descriptionBuilder = task.block.description ? task.block.description.trim() : '';

        // Add trailing full stop if no character ending it.
        descriptionBuilder =
            R.match(/[a-zA-Z0-9]$/, descriptionBuilder).length > 0 ? `${descriptionBuilder}.` : descriptionBuilder;

        return descriptionBuilder;
    };

    return {
        block,
        blockCategory,
        blockType,
        availableDataframes,
        dataframeParameters,
        parameters,
        dataframeParameterNames,
        columnsPerTask,
        getTask,
        description,
        assetParameterName,
    };
}
