import * as R from 'ramda';
import { computed, Ref } from '@vue/composition-api';
import { InputParameter } from '../../types';
import { S } from '@/app/utilities';

export function useDynamicColumnParameter(
    parameter: Ref<InputParameter>,
    workflowConfiguration: Ref<any>,
    dataframes: Ref<any>,
    columnsPerTask: Ref<any>,
) {
    // Calculates they type of the column depending on
    const type = computed(() => {
        if (
            !R.isNil(parameter.value.validation) &&
            !R.isNil(parameter.value.validation.dynamic) &&
            R.has(parameter.value.validation.dynamic, workflowConfiguration.value) &&
            S.has('ref', workflowConfiguration.value[parameter.value.validation.dynamic]) &&
            S.has((workflowConfiguration.value[parameter.value.validation.dynamic] as any).ref, dataframes.value) &&
            S.has(
                'task',
                dataframes.value[(workflowConfiguration.value[parameter.value.validation.dynamic] as any).ref],
            ) &&
            S.has(
                dataframes.value[(workflowConfiguration.value[parameter.value.validation.dynamic] as any).ref].task,
                columnsPerTask.value,
            )
        ) {
            const allColumnsOfDataframe =
                columnsPerTask.value[
                    dataframes.value[(workflowConfiguration.value[parameter.value.validation.dynamic] as any).ref].task
                ];
            const columnName = (workflowConfiguration.value[parameter.value.validation.dynamic] as any).value;
            if (S.has(columnName, allColumnsOfDataframe)) {
                return allColumnsOfDataframe[columnName];
            }
        }

        return null;
    });
    // Calculates which columns to show in the dropdown and filters
    // by column type/types if any defined
    const columns = computed(() => {
        if (R.isNil(type.value)) {
            return null;
        }
        const paramType = `${type.value}`;
        return (parameter.value.validation as any).values.reduce(
            (acc: { label: string; value: string }[], option: { value: string; types: string[]; text?: string }) => {
                if (option.types.includes(paramType)) {
                    acc.push({
                        value: option.value,
                        label:
                            S.has('text', option) && !R.isNil(option.text) && !R.isEmpty(option.text)
                                ? option.text
                                : option.value,
                    });
                }
                return acc;
            },
            [],
        );
    });

    return { columns };
}
