import store from '@/app/store';
import { SearchAPI } from '@/modules/search/api';
import { useAxios } from '@/app/composable';
import { computed, Ref, ref } from '@vue/composition-api';

export function usePipelineSearch(
    root: any,
    pipelineType: Ref<string>,
    noOfItems: Ref<number>,
    id: Ref<string>,
    excludeIds: Ref<string[]> = ref<string[]>([]),
    statuses: string[] = [],
) {
    const { exec, loading } = useAxios(true);

    const user = computed(() => store.state.auth.user);
    const pipelines = ref<any>([]);
    const totalItems = ref<number>(0);

    const searchQuery = {
        pipelineType: pipelineType.value,
        query: {
            input: { text: '*' },
            settings: { caseSensitive: false, partialMatch: true },
            ids: [],
            excludeIds: [],
        },
        facets: {},
        filters: {
            status: [],
        },
        pagination: { page: 1, size: noOfItems.value },
        sortBy: { field: 'selected', asc: false },
        settings: { organisationId: user.value.organisationId, userId: 0 },
    };

    const refetch = (searchText: string, page: number): Promise<void> => {
        return new Promise((resolve) => {
            searchQuery.query.ids = id.value ? [id.value] : ([] as any);
            searchQuery.query.excludeIds = excludeIds?.value ? excludeIds.value.filter((v) => v) : ([] as any);
            searchQuery.pipelineType = pipelineType.value;
            searchQuery.query.input.text = searchText ? searchText : '*';
            searchQuery.pagination.page = page;
            searchQuery.filters.status = statuses as any;

            exec(
                {
                    analytics: SearchAPI.sharedWorkflowsSearch,
                    'data-checkin': SearchAPI.sharedJobsSearch,
                }[pipelineType.value](searchQuery),
            )
                .then((res: any) => {
                    pipelines.value = res.data?.results;
                    totalItems.value = res.data?.total;
                    resolve();
                })
                .catch(() => {
                    (root as any).$toastr.e('Error while trying to retrieve datasets', 'Error');
                    resolve();
                });
        });
    };

    return { pipelines, loading, totalItems, refetch };
}
