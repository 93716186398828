























































import { Variable } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { DataType, DynamicOn, OtherInputParameterType } from '@/modules/workflow-designer/constants';
import { Loop } from '@/modules/workflow-designer/types';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { isNil, isEmpty, is, has } from 'ramda';
import { ExclamationIcon } from '@vue-hero-icons/solid';

import { ParameterComponent } from '../parameter-component.constants';

export default defineComponent({
    name: 'DynamicParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameters: {
            type: Object,
            required: true,
        },
        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        workflowConfiguration: {
            type: Object,
            default: () => {
                return {};
            },
        },
        assetStructureForTask: {
            type: Object,
            default: () => {},
        },
        forceUpdate: {
            type: Date,
            default: () => new Date(),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
    },
    components: { ExclamationIcon },
    setup(props, { emit }) {
        const currentValue = ref<any>(props.value);

        const dynamicOnStructure = computed(() => {
            if (
                // Dynamic on dataframe columns
                props.forceUpdate &&
                S.has(props.parameter.validation.dynamic, props.workflowConfiguration) &&
                S.has('ref', props.workflowConfiguration[props.parameter.validation.dynamic]) &&
                S.has(props.workflowConfiguration[props.parameter.validation.dynamic].ref, props.dataframes) &&
                S.has('task', props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref]) &&
                S.has(
                    props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref].task,
                    props.columnsPerTask,
                )
            ) {
                return props.columnsPerTask[
                    props.dataframes[props.workflowConfiguration[props.parameter.validation.dynamic].ref].task
                ];
            } else if (
                // Dynamic on asset columns
                props.forceUpdate &&
                S.has(props.parameter.validation.dynamic, props.workflowConfiguration) &&
                !isNil(props.assetStructureForTask) &&
                has('columnsWithTypes', props.assetStructureForTask)
            ) {
                return props.assetStructureForTask.columnsWithTypes;
            }
            return null;
        });

        /**
         * Calculates if depending input parameter is of unknown type
         */
        const typeIsUnknown = computed((): boolean => {
            // can't calculate if there is no structure
            if (isNil(dynamicOnStructure.value)) return false;
            // if the value cannot be found then the type is unknown
            if (isNil(props.workflowConfiguration[props.parameter.validation.dynamic])) return true;

            // in case of an array we check that there is at least one value with uknown type
            if (is(Array, props.workflowConfiguration[props.parameter.validation.dynamic].value)) {
                return isEmpty(
                    props.workflowConfiguration[props.parameter.validation.dynamic].value.reduce(
                        (acc: string[], param: string) => {
                            if (!acc.includes(dynamicOnStructure.value[param])) {
                                acc.push(dynamicOnStructure.value[param]);
                            }
                            return acc;
                        },
                        [],
                    ),
                );
            }

            // in case of a single value we check that the parameter has an unknown type
            return isNil(
                dynamicOnStructure.value[props.workflowConfiguration[props.parameter.validation.dynamic].value],
            );
        });

        // Calculates the values the depending block takes
        const parameterValues = computed(() => {
            if (
                props.forceUpdate &&
                !isNil(props.parameter.validation.values) &&
                !isEmpty(props.parameter.validation.values) &&
                S.has(props.parameter.validation.dynamic, props.workflowConfiguration) &&
                S.has('value', props.workflowConfiguration[props.parameter.validation.dynamic]) &&
                S.has('dynamicOn', props.parameter.validation)
            ) {
                let valueToCheck: any = null;
                if (props.parameter.validation.dynamicOn === DynamicOn.Value) {
                    valueToCheck = props.workflowConfiguration[props.parameter.validation.dynamic].value;
                }
                if (props.parameter.validation.dynamicOn === DynamicOn.Type && !isNil(dynamicOnStructure.value)) {
                    valueToCheck = is(Array, props.workflowConfiguration[props.parameter.validation.dynamic].value)
                        ? props.workflowConfiguration[props.parameter.validation.dynamic].value.reduce(
                              (acc: string[], param: string) => {
                                  if (!acc.includes(dynamicOnStructure.value[param])) {
                                      acc.push(dynamicOnStructure.value[param]);
                                  }
                                  return acc;
                              },
                              [],
                          )
                        : dynamicOnStructure.value[
                              props.workflowConfiguration[props.parameter.validation.dynamic].value
                          ];
                }

                if (!isNil(valueToCheck)) {
                    return props.parameter.validation.values.reduce(
                        (
                            acc: { value: string; text: string }[],
                            param: { value: string; text?: string; on: string[] },
                        ) => {
                            // When to add parameter:
                            // 1. param.on is not defined (should be included always)
                            // 2. when param.on is defined and valueToCheck is an array and we have a match
                            // 3. when it's not an array and we have a match
                            if (
                                isNil(param.on) ||
                                (is(Array, valueToCheck) &&
                                    param.on.filter((p: string) => valueToCheck.includes(p)).length > 0) ||
                                (!is(Array, valueToCheck) && param.on.includes(valueToCheck))
                            ) {
                                acc.push({
                                    value: param.value,
                                    text: !isNil(param.text) && !isEmpty(param.text) ? param.text : param.value,
                                });
                            }
                            return acc;
                        },
                        [],
                    );
                }
            }

            return [];
        });

        // Calculates column types to include (if list is empty then include all)
        const parameterComponent = computed((): ParameterComponent | undefined => {
            // Dynamic on structure is only applicable on dynamicOn type
            if (isNil(dynamicOnStructure.value) && props.parameter.validation.dynamicOn === DynamicOn.Type)
                return undefined;

            if (parameterValues.value.length === 0) {
                const columnName = props.workflowConfiguration[props.parameter.validation.dynamic]?.value;

                // match column type if single column
                if (!Array.isArray(columnName) && S.has(columnName, dynamicOnStructure.value)) {
                    return ParameterComponent.find(props.parameter.category, dynamicOnStructure.value[columnName]);
                } else if (Array.isArray(columnName)) {
                    // match a specific type only if all selected columns have the same type otherwise none
                    const baseDynamicType: DataType | OtherInputParameterType | null | undefined =
                        dynamicOnStructure.value[columnName[0]];
                    if (
                        baseDynamicType &&
                        columnName.every((name: string) => dynamicOnStructure.value[name] === baseDynamicType)
                    )
                        return ParameterComponent.find(props.parameter.category, baseDynamicType);
                }
            }

            return ParameterComponent.STRING;
        });

        const dynamicParameter = computed(() => {
            if (parameterValues.value.length > 0) {
                return {
                    ...props.parameter,
                    validation: {
                        ...(props.parameter.validation ? props.parameter.validation : {}),
                        values: parameterValues.value,
                    },
                };
            }
            return props.parameter;
        });

        const isDropdown = computed(
            () =>
                !isNil(props.parameter.validation) &&
                !isNil(props.parameter.validation.values) &&
                !isEmpty(props.parameter.validation.value),
        );

        const dynamicComponentKey = computed(() =>
            parameterComponent.value
                ? `${parameterComponent.value.toString()}_${JSON.stringify(dynamicParameter.value)}`
                : undefined,
        );

        // Triggered when there is a change in the value
        const change = (updatedValue: any) => {
            emit('change', updatedValue);
        };

        const parameterNameCalculator = (name: string) => {
            const trimmedName = name.split('_').join(' ');

            return trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);
        };

        // Watch changes in the incoming value to adapt the currently held value
        watch(
            () => props.value,
            (newValue: any) => {
                currentValue.value = newValue;
            },
        );

        watch(
            () => parameterComponent.value,
            (component: ParameterComponent | undefined, oldComponent: ParameterComponent | undefined) => {
                // reset current value if component changes
                if (oldComponent && oldComponent.component?.name !== component?.component?.name)
                    currentValue.value = null;
                emit('set-inner-component', component);
            },
            { immediate: true },
        );

        return {
            parameterNameCalculator,
            currentValue,
            change,
            parameterComponent,
            parameterValues,
            dynamicParameter,
            dynamicComponentKey,
            typeIsUnknown,
            isDropdown,
            dynamicOnStructure,
        };
    },
});
