var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ParameterValidation',{staticClass:"flex flex-col",attrs:{"rules":_vm.rules,"name":_vm.name,"changeDate":_vm.changeDate,"strict":_vm.strict},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var errorTextColour = ref.errorTextColour;
return [_c('AdvancedSelect',{staticClass:"w-full",attrs:{"items":_vm.columns,"keyField":"value","labelField":"label","multiple":_vm.parameter.multiple,"errorColour":errorTextColour,"errors":errors,"disabled":_vm.readonly,"expandInPlace":"","noOfItems":30},on:{"change":_vm.change},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fullItem = ref.fullItem;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('ShortTypeBadge',{attrs:{"typeName":fullItem.type}}),_c('span',{staticClass:"w-56 reverse-truncate"},[_vm._v(_vm._s(fullItem.label))])],1)]}},{key:"tooltip",fn:function(ref){
var fullItem = ref.fullItem;
return [_c('div',{staticClass:"flex flex-col p-2 space-y-2"},[(!fullItem.selectable)?_c('span',[_c('span',{staticClass:"text-danger-500"},[_vm._v("Unselectable")]),_vm._v(" because currently selecting only "),_c('span',{staticClass:"text-green-400"},[_vm._v(_vm._s(_vm.columnTypeLabels.join(',')))])]):_vm._e(),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 text-xs lowercase rounded",class:[_vm.getTextColor(fullItem.type), _vm.getBgColor(fullItem.type)]},[_vm._v(_vm._s(_vm.getLabel(fullItem.type)))]),_c('span',[_vm._v(_vm._s(fullItem.label))])])])]}},(_vm.hasSelectedColumns)?{key:"summary",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentValue.join(', '))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),_c('input',{attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.currentValue}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }