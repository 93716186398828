import { computed, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { BlockCategory, BlockType } from '../constants';
import { Loop, Pipeline, Task } from '../types';
import { useAssetStructureParameter } from './parameters/asset-structure-parameter';
import { useTasks } from './tasks';

export function useTaskConfiguration(
    task: Ref<Task>,
    tasks: Ref<Map<string, Task>>,
    pipelines: Ref<Pipeline[]>,
    loops: Ref<Loop[]>,
) {
    const tasksComposable = useTasks(tasks);

    const blockCategory = computed(() => tasksComposable.blockCategory(task.value));

    const blockType = computed(() => tasksComposable.blockType(task.value));

    const dataframeParameters = computed(() => tasksComposable.dataframeParameters(task.value));

    const parameters = computed(() => tasksComposable.parameters(task.value));

    const dataframeParameterNames = computed((): string[] => tasksComposable.dataframeParameterNames(task.value));

    const dataframesFromTask = (t: Task) => R.pick(dataframeParameterNames.value, t.configuration);

    const dataframeParametersConfig = ref<any>(dataframesFromTask(task.value));

    const liveUpstreamTasks = computed(() =>
        Object.keys(dataframeParametersConfig.value).reduce((acc: Task[], dataframeKey: string) => {
            const dataframeTaskId = dataframeParametersConfig.value[dataframeKey].task;
            if (dataframeTaskId && tasks.value.has(dataframeTaskId)) {
                const dataframeTask: Task = tasks.value.get(dataframeTaskId) as Task;

                acc.push(dataframeTask);
            }
            return acc;
        }, []),
    );

    const allMachineLearningDataframes = computed(() => {
        const upstream = Object.keys(dataframeParametersConfig.value).reduce((acc: Task[], dataframeKey: string) => {
            const dataframeTaskId = dataframeParametersConfig.value[dataframeKey].task;
            if (dataframeTaskId && tasks.value.has(dataframeTaskId)) {
                const dataframeTask: Task = tasks.value.get(dataframeTaskId) as Task;
                if (
                    dataframeTask.block.category === BlockCategory.MachineLearning &&
                    dataframeTask.block.supportedPipelines &&
                    dataframeTask.block.supportedPipelines.some((sp: string) =>
                        task.value.block.supportedPipelines.includes(sp),
                    )
                ) {
                    acc.push(dataframeTask);
                }
            }
            return acc;
        }, []);
        const downstream = task.value.downstreamTaskIds.reduce((acc: Task[], taskId: string) => {
            if (taskId && tasks.value.has(taskId)) {
                const dataframeTask: Task = tasks.value.get(taskId) as Task;
                if (
                    dataframeTask.block.category === BlockCategory.MachineLearning &&
                    dataframeTask.block.supportedPipelines &&
                    dataframeTask.block.supportedPipelines.some((sp: string) =>
                        task.value.block.supportedPipelines.includes(sp),
                    )
                ) {
                    acc.push(dataframeTask);
                }
            }
            return acc;
        }, []);

        return { downstream, upstream };
    });

    const availableMachineLearningDataframes = computed(() =>
        [...allMachineLearningDataframes.value.downstream, ...allMachineLearningDataframes.value.upstream].reduce(
            (acc: Task[], t: Task) => {
                if (pipelines.value.filter((pl: Pipeline) => pl.tasks.includes(t.id)).length === 0) {
                    acc.push(t);
                }
                return acc;
            },
            [],
        ),
    );

    const availablePipeline = computed((): Pipeline | undefined => {
        return pipelines.value.find(
            (pipeline: Pipeline) =>
                (pipeline.tasks.length > 0 &&
                    task.value.block.supportedPipelines.includes(pipeline.type) &&
                    R.pluck('id', allMachineLearningDataframes.value.upstream).includes(
                        R.last(pipeline.tasks) as string,
                    )) ||
                R.pluck('id', allMachineLearningDataframes.value.downstream).includes(pipeline.tasks[0] as string) ||
                pipeline.tasks.includes(task.value.id),
        );
    });

    const canBeInPipeline = computed(() => {
        return (
            (task.value.block.category === BlockCategory.MachineLearning &&
                availableMachineLearningDataframes.value.length > 0) ||
            !R.isNil(availablePipeline.value)
        );
    });

    const parametersConfig = ref<any>(
        Object.keys(task.value.configuration).reduce((acc: any, key: string) => {
            if (!dataframeParameterNames.value.includes(key)) {
                acc[key] = { ...task.value.configuration[key] };
            }
            return acc;
        }, {}),
    );

    const availableDataframes = computed(() => tasksComposable.availableDataframes(task.value));

    const description = computed(() => tasksComposable.description(task.value));

    const getTask = (taskId: string) => tasksComposable.getTask(taskId);

    const savedInLoopId: Ref<string | null> = computed(() => {
        const involvedLoops = loops.value.filter((loop: Loop) => loop.tasks.includes(task.value.id));
        return involvedLoops.length > 0 ? involvedLoops[0].startFor : null;
    });

    const currentLoopId: Ref<string | null> = ref<string | null>(null);

    const canBeInForLoopId = computed(() => {
        if (
            blockCategory.value &&
            ![BlockCategory.Output, BlockCategory.Control].includes(blockCategory.value.category)
        ) {
            let loopId: string | null = null;
            liveUpstreamTasks.value.forEach((upstreamTask: any) => {
                if (loopId) return;
                loops.value.forEach((loop: Loop) => {
                    if (loop.tasks.includes(upstreamTask.id)) loopId = loop.startFor;
                });
            });

            return loopId;
        }
        return null;
    });

    // calculates for loop object task is currently in
    const currentLoops: Ref<Loop[]> = computed(() =>
        loops.value.filter((loop: Loop) => loop.startFor === currentLoopId.value),
    );

    const availableVariables = computed(() => {
        if (!currentLoopId.value) return {};
        if (currentLoopId.value === task.value.id) return {}; // exclude start loop task itself
        return loops.value.reduce((acc: any, loop: Loop) => {
            if (loop.startFor === currentLoopId.value) acc = { ...acc, ...loop.variables };
            return acc;
        }, {});
    });
    const assetParameterName = computed(() => tasksComposable.assetParameterName(task.value));

    const taskAssetId = computed(() =>
        assetParameterName.value ? parametersConfig.value[assetParameterName.value]?.value : null,
    );

    const { assetStructureForTask, selectedAsset } = useAssetStructureParameter(taskAssetId);

    watch(
        () => task.value,
        (newTask: Task | null) => {
            dataframeParametersConfig.value = newTask ? dataframesFromTask(newTask) : [];
        },
    );

    watch(
        () => savedInLoopId.value,
        (loopId: string | null) => {
            currentLoopId.value = loopId;
        },
        { immediate: true },
    );

    const previousBlockIsLoopBlock = computed(() =>
        dataframeParameterNames.value?.some(
            (paramName: string) =>
                canBeInForLoopId.value && dataframeParametersConfig.value[paramName]?.task === canBeInForLoopId.value,
        ),
    );

    watch(
        () => previousBlockIsLoopBlock.value,
        (isPreviousLoopBlock: boolean) => {
            // if the block is a for loop itself then this is the currentLoopId
            if (
                blockCategory.value &&
                blockCategory.value.category === BlockCategory.Control &&
                blockType.value === BlockType.Loop
            )
                currentLoopId.value = task.value.id;
            else if (isPreviousLoopBlock) {
                currentLoopId.value = canBeInForLoopId.value;
            } else if (!canBeInForLoopId.value) {
                currentLoopId.value = null;
            }
        },
        { immediate: true },
    );

    return {
        blockCategory,
        blockType,
        dataframeParametersConfig,
        parametersConfig,
        availableDataframes,
        dataframeParameters,
        parameters,
        description,
        getTask,
        canBeInPipeline,
        allMachineLearningDataframes,
        availablePipeline,
        availableMachineLearningDataframes,
        availableVariables,
        currentLoopId,
        savedInLoopId,
        canBeInForLoopId,
        assetStructureForTask,
        currentLoops,
        previousBlockIsLoopBlock,
        selectedAsset,
    };
}
