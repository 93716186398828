


























































































































import { ConfirmButton, VariableAwareInput } from '@/app/components';
import { VariableType } from '@/app/constants';
import { Variable } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { integerValidator } from '@/app/validators';
import { PlusIcon } from '@vue-hero-icons/solid';
import { useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { extend } from 'vee-validate';
import ParameterValidation from '../ParameterValidation.vue';

extend('integer', integerValidator);

export default defineComponent({
    name: 'IntegerParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: { ParameterValidation, ConfirmButton, VariableAwareInput, PlusIcon },
    setup(props, { emit }) {
        const NUMBER_REGEX = new RegExp(/^-?\d+$/);

        const validationValue = ref<any>(
            props.parameter.multiple && R.isNil(props.value?.value) ? [] : props.value?.value,
        );

        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);

        const isNumber = (valToCheck: any) =>
            !R.isNil(valToCheck) && !R.isEmpty(valToCheck) && NUMBER_REGEX.test(String(valToCheck));

        const { currentValue, isArray, addAnother, deleteEntry, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                if (!S.has('value', incomingValue) || R.isNil(incomingValue?.value)) {
                    // Case no existing value defined
                    if (S.has('default', props.parameter.validation)) {
                        // Case default value defined in validation information
                        if (props.parameter.multiple) {
                            if (
                                R.isNil(props.parameter.validation.default) ||
                                R.isEmpty(props.parameter.validation.default)
                            ) {
                                return [];
                            }

                            if (R.is(Array, props.parameter.validation.default)) {
                                return props.parameter.validation.default.map((defaultVal: any) => {
                                    if (Object.keys(props.availableVariables).length > 0) return defaultVal;
                                    else return isNumber(defaultVal) ? Number(defaultVal) : null;
                                });
                            }
                        }
                        if (Object.keys(props.availableVariables).length > 0) return props.parameter.validation.default;
                        else
                            return isNumber(props.parameter.validation.default)
                                ? Number(props.parameter.validation.default)
                                : null;
                    }
                    // Case no default value defined in validation information then take default empty value
                    return props.parameter.multiple ? [] : null;
                }
                if (S.has('value', incomingValue) && !R.isNil(incomingValue?.value) && props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking multiple values
                    return R.is(Array, incomingValue.value)
                        ? incomingValue.value.map((v: any) => {
                              if (Object.keys(props.availableVariables).length > 0) return v;
                              else if (isNumber(v)) return Number(v);
                              else return null;
                          })
                        : [incomingValue.value];
                }
                if (!R.isNil(incomingValue.value) && !props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking a single value
                    return incomingValue.value;
                }
                return props.parameter.multiple ? [] : null;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    let newValue = null;
                    if (props.parameter.multiple) {
                        if (
                            currentValue.value &&
                            !R.isNil(currentValue.value) &&
                            !R.isEmpty(currentValue.value) &&
                            R.is(Array, currentValue.value)
                        ) {
                            newValue = currentValue.value;
                        } else if (
                            currentValue.value &&
                            !R.isNil(currentValue.value) &&
                            !R.isEmpty(currentValue.value) &&
                            !R.is(Array, currentValue.value)
                        ) {
                            newValue = [currentValue.value];
                        } else {
                            newValue = [];
                        }
                    } else {
                        newValue = currentValue.value;
                    }

                    emit('change', { value: newValue });
                }
            },
        );

        const step = computed(() => {
            if (
                S.has('validation', props.parameter) &&
                !R.isNil(props.parameter.validation) &&
                !R.isEmpty(props.parameter.validation) &&
                S.has('range', props.parameter.validation) &&
                !R.isNil(props.parameter.validation.range) &&
                !R.isEmpty(props.parameter.validation.range) &&
                S.has('step', props.parameter.validation.range) &&
                !R.isNil(props.parameter.validation.range.step) &&
                !R.isEmpty(props.parameter.validation.range.step)
            ) {
                return props.parameter.validation.range.step;
            }
            return null;
        });

        const validate = (newValue: string | number, index?: number) => {
            if (!R.isNil(index) && !R.is(Array, validationValue.value)) validationValue.value = [validationValue.value];
            if (!R.isNil(index)) validationValue.value[index] = newValue;
            else validationValue.value = newValue;
        };

        // disable add another button if an input value is empty i.e. null
        const disableAddAnother = computed(
            () =>
                R.is(Array, currentValue.value) &&
                currentValue.value.length > 0 &&
                currentValue.value.filter((val: any) => val === null).length > 0,
        );

        watch(
            () => validationValue.value,
            () => change(false),
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        // Set the initial value on load
        change(false);

        return {
            currentValue,
            isArray,
            validationValue,
            addAnother,
            deleteEntry,
            change,
            step,
            changeDate,
            VariableType,
            validate,
            disableAddAnother,
        };
    },
});
