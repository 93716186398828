import { FacetFilter, FacetFilterType, useFacetsFilters, useFeatureFlags } from '@/app/composable';
import { computed } from '@vue/composition-api';

export function useWorkflowFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();
    const { isEnabled } = useFeatureFlags();

    const status = {
        label: 'Status',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'status'),
        options: [
            { value: 'completed', label: 'Completed' },
            { value: 'failed', label: 'Failed' },
            { value: 'draft', label: 'Configuration' },
            { value: 'ready', label: 'Execution Pending' },
            { value: 'running', label: 'Running' },
            { value: 'suspended', label: 'Suspended' },
            { value: 'queued', label: 'Queued' },
            { value: 'updating', label: 'Update' },
            { value: 'cancelled', label: 'Cancelled' },
        ],
    };

    const configurationIncluding = {
        label: 'Configuration Including',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'configurationIncluding'),
        options: [
            { value: 'visualisations', label: 'Visualisation' },
            { value: 'schedules', label: 'Schedule' },
            { value: 'triggers', label: 'Custom Trigger' },
        ],
    };

    const blockCategory = {
        label: 'Block Category',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'blockCategory'),
        options: [
            { value: 'prep', label: 'Data Preparation' },
            { value: 'ml train', label: 'ML Train' },
            { value: 'ml apply', label: 'ML Apply' },
            { value: 'ml evaluate', label: 'ML Evaluate' },
            { value: 'control', label: 'Control' },
        ],
    };

    const framework = {
        label: 'Framework ',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'framework'),
        options: [
            { value: 'spark3', label: 'Spark' },
            { value: 'python3', label: 'Python' },
        ],
    };

    const location = computed(() => {
        const options = [{ value: 'cloud', label: 'Cloud' }];
        if (isEnabled('on-premise')) options.push({ value: 'onPremise', label: 'On-Premise' });
        return {
            label: 'Execution Location',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'location'),
            options,
        };
    });

    const dateUpdated = {
        label: 'Date Updated Range',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'dateUpdatedMin', false),
            max: defaultValue(get, 'dateUpdatedMax', false),
        },
    };

    const user = {
        // displayed only to "shared" pipelines tab
        label: 'User',
        type: FacetFilterType.Treeselect,
        placeholder: 'Select user',
        default: defaultValue(get, 'user'),
        options: [],
    };

    const pipeline = {
        label: 'Pipeline ID',
        placeholder: 'Enter pipeline ID',
        type: FacetFilterType.Text,
        default: { id: defaultValue(get, 'pipeline', false) },
    };

    const workflowFilters = computed(
        (): Record<string, FacetFilter> => {
            return {
                pipeline,
                status,
                configurationIncluding,
                blockCategory,
                framework,
                location: location.value,
                dateUpdated,
                user,
            };
        },
    );

    const reducedWorkflowFilters = reducedFacetsFilters(workflowFilters);

    return { workflowFilters, reducedWorkflowFilters };
}
