import { TriggerSource } from '../constants';

export function useTriggerSource() {
    const label = (triggerSourceKey: TriggerSource): string | undefined => {
        switch (triggerSourceKey) {
            case TriggerSource.Manual:
                return 'Manual';
            case TriggerSource.Schedule:
                return 'Schedule';
            case TriggerSource.Trigger:
                return 'Custom';
            case TriggerSource.Api:
                return 'API';
            default:
                return undefined;
        }
    };

    return { label };
}
