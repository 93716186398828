import { Model } from '@/app/interfaces';
import { Ref, computed, ref, watch } from '@vue/composition-api';
import { is } from 'ramda';
import store from '@/app/store';

export function useAvailableWorkflowModels(id: Ref<string> | string, immediate = false) {
    const workflowId = computed(() => (is(String, id) ? id : id.value));
    const models = computed(() => store.getters.pipelineDesigner.availableModels || []);
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingModels);

    const refetch = async () => {
        store.dispatch.pipelineDesigner.loadAvailableModels(true);
    };

    watch(
        () => workflowId.value,
        (newId: string, oldId: string | undefined) => {
            if (newId !== oldId) store.dispatch.pipelineDesigner.loadAvailableModels(false);
        },
        { immediate },
    );
    return { models, loading, refetch };
}
