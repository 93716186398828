
































































import { AccessLevelDynamicBadgeCompact, AdvancedSelect } from '@/app/components';
import { S } from '@/app/utilities';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { Asset } from '@/modules/asset/types';
import { useDatasetAssetParameter, useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import { LightningBoltIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'DatasetAssetParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
            default: () => {
                return new Date();
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        runnerId: {
            type: String,
            default: null,
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
        selectedAsset: {
            type: Object as PropType<Asset>,
            default: null,
        },
    },
    components: {
        AdvancedSelect,
        ParameterValidation,
        LightningBoltIcon,
        AccessLevelDynamicBadgeCompact,
    },
    setup(props, { emit, root }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const runnerId = ref<string>(props.runnerId);
        const noOfItems = ref<number>(30);
        const selectedItems = computed(() =>
            props.selectedAsset ? [{ ...props.selectedAsset, id: props.selectedAsset.id.toString() }] : [],
        );

        const { assets, refetch, totalItems, totalAssets } = useDatasetAssetParameter(runnerId, root, noOfItems);
        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                let resultingValue = null;
                if (incomingValue && S.has('value', incomingValue) && !R.isNil(incomingValue.value)) {
                    // Case of an existing value already defined
                    resultingValue = `${incomingValue.value}`;
                } else if (S.has('default', props.parameter.validation)) {
                    // Case of no value defined but default is available
                    resultingValue = props.parameter.validation.default;
                }
                return resultingValue;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    const assetId =
                        R.isNil(currentValue.value) || R.isEmpty(currentValue.value)
                            ? null
                            : parseInt(currentValue.value, 10);
                    emit('change', { value: assetId });
                }
            },
        );

        const accessLevelText = (accessLevel: AccessLevel) => {
            if (R.isNil(accessLevel)) return;

            switch (accessLevel) {
                case AccessLevel.Private:
                    return 'Visible only to me';
                case AccessLevel.OrganisationLevel:
                    return 'Visible to my organisation';
                case AccessLevel.SelectiveSharing:
                    return 'Selective sharing';
                case AccessLevel.Restricted:
                    return 'Restricted within organisation';
                case AccessLevel.Public:
                    return 'Public access';
                default:
                    return null;
            }
        };

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        // Set the initial value on load
        change(null, true);

        return {
            currentValue,
            change,
            assets,
            changeDate,
            refetch,
            totalItems,
            noOfItems,
            totalAssets,
            selectedItems,
            accessLevelText,
        };
    },
});
