






















































import { S } from '@/app/utilities';
import { InputParameter } from '@/modules/workflow-designer/types';
import { computed, defineComponent, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import ParameterValidation from '../ParameterValidation.vue';

import { AdvancedSelect, ShortTypeBadge } from '@/app/components';
import { useDataType, useParameter } from '@/modules/workflow-designer/composable';

export default defineComponent({
    name: 'AssetColumnParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {},
        },
        parameter: {
            type: Object,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        assetStructureForTask: {
            type: Object,
            default: () => {},
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: { AdvancedSelect, ParameterValidation, ShortTypeBadge },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);

        const { getLabel, getTextColor, getBgColor, getBorderColor } = useDataType();

        const columnTypeLabels = computed(
            () => props.assetStructureForTask?.columnTypes?.value?.map((t: string) => getLabel(t)) || [],
        );
        const columns = computed(() => props.assetStructureForTask?.columns || []);

        const sendChange = (newValue: any) => {
            emit('change', { value: newValue });
        };

        const findInitialValue = (incomingValue: any) => {
            let resultingValue = [];
            if (incomingValue && S.has('value', incomingValue) && !R.isNil(incomingValue.value)) {
                // Case of an existing value already defined
                resultingValue = incomingValue.value;
            } else if (S.has('default', props.parameter.validation)) {
                // Case of no value defined but default is available
                resultingValue = props.parameter.validation.default || [];
            }
            return resultingValue;
        };

        const changeFunc = () => {
            if (!R.isNil(currentValue)) {
                emit('change', { value: currentValue.value });
            }
        };

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,
            // the current value of the parameter
            value as Ref<any>,
            // if the parameter is visible or not
            visible,
            // send explicitly a new value
            sendChange,
            // find initial value function
            findInitialValue,
            // on value change
            changeFunc,
        );

        const hasSelectedColumns = computed(() => R.is(Array, currentValue.value) && currentValue.value.length > 1);

        // Set the initial value on load
        change();

        watch(
            () => columns.value,
            (incoming: any, old: any) => {
                if (old && !R.isEmpty(old) && !R.equals(old, incoming)) {
                    const newColumns = incoming.map((col: any) => col.value);
                    if (props.parameter.multiple) {
                        currentValue.value = currentValue.value.filter((col: string) => newColumns.includes(col));
                        change();
                    } else if (!R.isNil(currentValue.value) && !newColumns.includes(currentValue.value)) {
                        currentValue.value = findInitialValue(null);
                        change();
                    }
                }
            },
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        return {
            currentValue,
            change,
            changeDate,
            columns,
            columnTypeLabels,
            getLabel,
            getTextColor,
            getBgColor,
            getBorderColor,
            hasSelectedColumns,
        };
    },
});
