














































































































































import {
    AdvancedSelect,
    FormModal,
    ShortTypeBadge,
    VariableAwareInput,
    AdvancedOrderedSelect,
    InputErrorIcon,
} from '@/app/components';
import { useVariableUtils } from '@/app/composable';
import { VariableType } from '@/app/constants';
import { Variable } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { useColumnParameter, useDataType, useParameter } from '@/modules/workflow-designer/composable';
import { DataType } from '@/modules/workflow-designer/constants';
import { OtherInputParameterType } from '@/modules/workflow-designer/constants/other-input-parameter-type.constants';
import { InputParameter } from '@/modules/workflow-designer/types';
import { CheckIcon, XIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'ColumnParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        orderColumns: {
            type: Boolean,
            default: false,
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
    },
    components: {
        AdvancedSelect,
        ParameterValidation,
        ShortTypeBadge,
        VariableAwareInput,
        XIcon,
        CheckIcon,
        FormModal,
        AdvancedOrderedSelect,
        InputErrorIcon,
    },
    setup(props, { emit }) {
        const { getDisplayValue, getMatchedVariables } = useVariableUtils();
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const parameter = ref<any>(props.parameter);
        const dataframes = ref<any>(props.dataframes);
        const columnsPerTask = ref<any>(props.columnsPerTask);
        const hasAvailableVariables = computed(() => Object.keys(props.availableVariables).length > 0);
        const { getLabel, getTextColor, getBgColor, getBorderColor } = useDataType();
        const addCustomMode = ref<boolean>(false);
        const customValue = ref<string | null>(null);
        const validationValue = ref<any>(props.value);
        const showOrderedSelectableColumnsModal = ref<boolean>(false);
        const temporaryColumns = ref<{ selected: any[]; unselected: any[]; structure: any[] }>({
            selected: [],
            unselected: [],
            structure: [],
        });

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                let resultingValue: any = parameter.value.multiple ? [] : null;
                if (incomingValue && S.has('value', incomingValue) && !R.isNil(incomingValue.value)) {
                    // Case of an existing value already defined
                    if (columns?.value && !hasAvailableVariables.value) {
                        // if there is a list of available columns, make sure that the selected columns are a subset
                        const allColumns = R.pluck('value', columns.value);
                        if (parameter.value.multiple && R.is(Array, incomingValue.value)) {
                            // keep only columns that are selected and are availalble
                            resultingValue = R.intersection(incomingValue.value, allColumns);
                        } else {
                            // only keep the selected column if available
                            resultingValue = allColumns.includes(incomingValue.value)
                                ? incomingValue.value
                                : props.parameter?.validation?.default || null;
                        }
                    } else {
                        resultingValue = incomingValue.value;
                    }
                    if (!R.equals(resultingValue, incomingValue.value)) {
                        // if the resulting value is different than the incoming value, emit change event so the user can save
                        emit('change', { value: resultingValue, ref: props.parameter.validation.ref });
                    }
                } else if (S.has('default', props.parameter.validation)) {
                    // Case of no value defined but default is available
                    resultingValue = props.parameter.validation.default;
                }
                return resultingValue;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', { value: currentValue.value, ref: props.parameter.validation.ref });
                }
            },
        );

        const { columns, columnTypes } = useColumnParameter(
            parameter as Ref<InputParameter>,
            dataframes,
            columnsPerTask,
            currentValue,
        );

        const columnTypeLabels = computed(() => columnTypes.value.map((t: string) => getLabel(t)));
        const summary = computed(() => {
            if (!currentValue.value) return '';
            const items = R.is(Array, currentValue.value) ? currentValue.value : [currentValue.value];
            return S.sanitizeHtml(
                items
                    .map((v: string) => {
                        const matchedVariables = getMatchedVariables(v, props.availableVariables);
                        if (matchedVariables.length > 0) {
                            return getDisplayValue(v, matchedVariables);
                        }
                        return v;
                    })
                    .join(', '),
            );
        });

        const restrictToTypes = computed(() => {
            if (!R.isNil(props.parameter.type)) {
                // if the column parameter has a specifice type then we need to check if this is one of the known data types
                const foundType: any = Object.entries(DataType).find((entry) => entry[1] === props.parameter.type);
                // if it is known we need to split/trim and find the label to cover the case
                // where this is a Numeric data type meaning that it is both integer and double
                if (!R.isNil(foundType) && !R.isEmpty(foundType))
                    return foundType[1].split('||').map((t: string) => getLabel(t.trim()));
            }
            return undefined;
        });

        const updateColumns = (newColumns: any) => {
            let updatedValue = currentValue.value;
            if (!R.isNil(currentValue.value) && !R.isEmpty(currentValue.value)) {
                if (R.is(Array, currentValue.value)) {
                    updatedValue = currentValue.value.filter((column: string) => {
                        return hasAvailableVariables.value || R.find(R.propEq('value', column))(newColumns);
                    });
                } else {
                    updatedValue =
                        !hasAvailableVariables.value &&
                        R.isNil(R.find(R.propEq('value', currentValue.value))(newColumns))
                            ? null
                            : currentValue.value;
                }
            }
            if (JSON.stringify(currentValue.value) !== JSON.stringify(updatedValue)) {
                currentValue.value = updatedValue;
                change();
            }
        };

        const addCustom = () => {
            addCustomMode.value = true;
        };

        const setCustom = () => {
            if (customValue.value) {
                if (props.parameter.multiple) currentValue.value.push(customValue.value);
                else currentValue.value = customValue.value;
            }

            addCustomMode.value = false;
            customValue.value = null;
            change();
        };

        const cancelCustom = () => {
            addCustomMode.value = false;
            customValue.value = null;
        };

        const validate = (newValue: string | number) => {
            validationValue.value = newValue;
        };

        const columnDisplay = (v: string) => {
            const matchedVariables = getMatchedVariables(v, props.availableVariables);
            if (matchedVariables.length > 0) {
                return getDisplayValue(v, matchedVariables);
            }
            return S.sanitizeHtml(v);
        };

        const orderedSelectableColumns = computed(() => {
            if (!columns.value || !props.parameter.multiple) return { selected: [], unselected: [], structure: [] };
            if (!currentValue.value) return { selected: [], unselected: columns.value, structure: columns.value };

            const selectedColumns = currentValue.value
                .map(
                    (name: string) =>
                        columns.value?.find((column: any) => column.value === name) ||
                        (getMatchedVariables(name, props.availableVariables)?.length
                            ? {
                                  label: name,
                                  selectable: true,
                                  type: VariableType.Unknown,
                                  value: name,
                                  custom: true,
                              }
                            : null),
                )
                .filter((v: any) => !R.isNil(v));

            const unselectedColumns = R.differenceWith(
                (x: any, y: any) => x.value === y.value,
                columns.value,
                selectedColumns,
            );

            return {
                selected: selectedColumns,
                unselected: unselectedColumns,
                structure: columns.value,
            };
        });

        const onCancelOrderedSelection = () => {
            temporaryColumns.value = { unselected: [], selected: [], structure: [] };
            showOrderedSelectableColumnsModal.value = false;
        };

        const onSaveOrderedSelection = () => {
            currentValue.value = temporaryColumns.value.selected.map((column: any) => column.value);
            showOrderedSelectableColumnsModal.value = false;
            change();
        };

        const isRequired = computed(() => !!props.parameter?.required || !!props.rules?.required);

        const openOrderedSelectableColumnsModal = () => {
            temporaryColumns.value = R.clone(orderedSelectableColumns.value);
            showOrderedSelectableColumnsModal.value = true;
        };

        watch(
            () => validationValue.value,
            () => change(),
        );

        watch(
            () => columns.value,
            (newColumns: any) => {
                updateColumns(newColumns);
            },
            { immediate: true },
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        change();

        return {
            S,
            currentValue,
            columns,
            columnTypeLabels,
            changeDate,
            change,
            getTextColor,
            getBgColor,
            getBorderColor,
            getLabel,
            addCustomMode,
            hasAvailableVariables,
            VariableType,
            validationValue,
            customValue,
            summary,
            validate,
            addCustom,
            setCustom,
            cancelCustom,
            columnDisplay,
            showOrderedSelectableColumnsModal,
            onCancelOrderedSelection,
            onSaveOrderedSelection,
            isRequired,
            updateColumns,
            OtherInputParameterType,
            temporaryColumns,
            openOrderedSelectableColumnsModal,
            restrictToTypes,
        };
    },
});
