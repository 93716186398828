import { Ref, computed, ref, watch } from '@vue/composition-api';
import { ExecutionLog, Task } from '../types';
import { isNil, has, is } from 'ramda';
import { ExecutionStatus } from '../constants';
import { useExecutionLogs } from './execution-logs';

const onChangeFunctions: Ref<Function[]> = ref([]);
const failureMessagesPerTask: Ref<Record<string, string[]>> = ref({});

export function useFailedTasks(tasks: Ref<Record<string, Task>>) {
    const activeExecutions = computed(() =>
        Object.values(tasks.value).reduce((acc: string[], task: Task) => {
            if (task.executions.length > 0) acc.push(task.executions[0].id);
            return acc;
        }, []),
    );
    const { errorLogs, onLogsChange } = useExecutionLogs(activeExecutions);

    onLogsChange(() => {
        failureMessagesPerTask.value = Object.values(tasks.value).reduce(
            (failedTasksMap: Record<string, string[]>, task: Task) => {
                const messages = Object.values(tasks.value).reduce((acc: string[], t: Task) => {
                    // if there are executions for the task
                    if (t.executions.length > 0) {
                        const lastExecution = t.executions[0];
                        // Then capture any error messages that belong to
                        // the task based on the following criteria
                        // 1. the log has a task id and matches the task in question
                        // 2. the log belongs under the task's execution logs but has no task id assigned
                        acc = [
                            ...acc,
                            ...errorLogs(lastExecution.id)
                                .filter(
                                    (log: ExecutionLog) =>
                                        log.taskId === task.id || (t.id === task.id && isNil(log.taskId)),
                                )
                                .map((log: ExecutionLog) => log.message),
                        ];

                        // if no logs found for this task
                        if (acc.length === 0 && t.id === task.id && lastExecution.status === ExecutionStatus.Failed) {
                            // then find if there are any other tasks to blame
                            const otherProblematicTasks = errorLogs(lastExecution.id).reduce(
                                (acc2: string[], log: ExecutionLog) => {
                                    if (log.taskId && log.taskId !== t.id && has(log.taskId, tasks.value))
                                        acc2.push(tasks.value[log.taskId].displayName);
                                    return acc2;
                                },
                                [],
                            );

                            // if there are no matching errors then it's likely that the logs are not available yet
                            if (otherProblematicTasks.length === 0) {
                                acc.push(
                                    `Execution failed: No error is available yet but you may try refreshing the logs in a little while`,
                                );
                            } else {
                                // otherwise indicate problematic tasks in message
                                acc.push(
                                    `Execution failed: Due to an issue in ${otherProblematicTasks
                                        .map((name: string) => `'${name}'`)
                                        .join(', ')}`,
                                );
                            }
                        }
                    }
                    return acc;
                }, []);
                failedTasksMap[task.id] = messages;
                return failedTasksMap;
            },
            {},
        );
    });

    const getFailureMessages = (task: Task | string): string[] => {
        const id = is(String, task) ? task : task.id;
        return has(id, failureMessagesPerTask.value) ? failureMessagesPerTask.value[id] : [];
    };

    const getFailureMessage = (task: Task | string): string | undefined => {
        const id = is(String, task) ? task : task.id;
        return has(id, failureMessagesPerTask.value) ? failureMessagesPerTask.value[id].join(', ') : undefined;
    };

    const getHasFailure = (task: Task | string) => {
        const id = is(String, task) ? task : task.id;
        return has(id, failureMessagesPerTask.value) && failureMessagesPerTask.value[id].length > 0;
    };

    const onChange = (f: Function) => {
        onChangeFunctions.value.push(f);
    };

    watch(
        () => failureMessagesPerTask.value,
        () => onChangeFunctions.value.forEach((f: Function) => f()),
        { immediate: true },
    );

    return { getFailureMessages, getFailureMessage, getHasFailure, onChange };
}
