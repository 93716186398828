



























































































































import { QueryBuilder } from '@/app/components';
import { Variable } from '@/app/interfaces';
import { useLogicalParameter, useParameter } from '@/modules/workflow-designer/composable';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { InputParameter, Loop } from '../../../types';
import ComplexParameter from './ComplexParameter.vue';

export default defineComponent({
    name: 'LogicalParameter',
    props: {
        workflowId: { type: String, required: true },
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        parametersDefinition: {
            type: Object,
            required: true,
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: { QueryBuilder, ComplexParameter, ValidationObserver },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const logicalParameterRef = ref<any>(null);
        const parameter = ref<any>(props.parameter);

        // for some parameters we want in the display to show
        // when they are actually null rather than just hidding them
        // this is to achieve this
        const specialParametersToShowNull: { param: string; if: { param: string; value: any } | null }[] = [
            // display 'value_operand' param as <null> if 'type' param has value 'value'
            { param: 'value_operand', if: { param: 'type', value: 'value' } },
        ];

        const findInitialValue = (incomingValue: any) => {
            return !R.isNil(incomingValue) && !R.isEmpty(incomingValue)
                ? incomingValue
                : { logical: 'AND', clauses: [] };
        };

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            findInitialValue,

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', currentValue.value);
                }
            },
        );

        const availableVariables = computed(() => props.availableVariables);
        const {
            newConditionTemplate,
            updateConditions,
            updateGroups,
            preprocessCondition,
            isConditionCalculator,
            isGroupCalculator,
            snakeCase,
            newGroupTemplate,
            conditionsExtractor,
            themeCalculator,
            complexParameterIncomingProcessor,
            complexParameterOutgoingProcessor,
            hasValue,
            viewClause,
        } = useLogicalParameter(parameter as Ref<InputParameter>, currentValue, availableVariables);

        const parameterChange = () => {
            if (logicalParameterRef.value) {
                logicalParameterRef.value.validate();
            }
        };

        // Set the initial value on load
        change();

        const resetConditions = (condition: { logical: string; clauses: any[] }, missingColumns: string[]) => {
            for (let i = 0; i < condition.clauses.length; i++) {
                const clause = condition.clauses[i];
                if (clause.logical && clause.clauses) resetConditions(clause, missingColumns);
                else {
                    for (const key of Object.keys(clause)) {
                        if (!R.isNil(clause[key].value) && clause[key].ref) {
                            const columns = Object.keys(props.columnsPerTask[props.dataframes[clause[key].ref].task]);
                            if (!columns.includes(clause[key].value)) missingColumns.push(clause[key].value);
                        }
                    }
                }
            }
        };

        watch(
            () => props.dataframes,
            () => {
                const missingColumns: string[] = [];
                resetConditions(currentValue.value, missingColumns);
                if (missingColumns.length) {
                    currentValue.value = findInitialValue(null);
                    change();
                }
            },
            { deep: true },
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        return {
            currentValue,
            change,
            changeDate,
            newConditionTemplate,
            updateConditions,
            updateGroups,
            preprocessCondition,
            isConditionCalculator,
            isGroupCalculator,
            snakeCase,
            newGroupTemplate,
            conditionsExtractor,
            themeCalculator,
            complexParameterIncomingProcessor,
            complexParameterOutgoingProcessor,
            hasValue,
            viewClause,
            logicalParameterRef,
            parameterChange,
            specialParametersToShowNull,
        };
    },
});
