import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { WorkflowType } from '@/app/constants';
import { TriggerSource } from '../constants';
import { useTriggerSource } from './trigger-source';
import { Ref, computed, ref } from '@vue/composition-api';

export function useExecutionHistoryFacets(
    get: Function,
    onChange: Function,
    workflowType: WorkflowType,
    longRunningExecution = false,
) {
    const { valueLabel, reducedFacetsFilters, defaultValue } = useFacetsFilters();
    const { label: getTriggerSourceLabel } = useTriggerSource();

    const status = {
        label: 'Status',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'status'),
        valueLabel,
    };

    const level = {
        label: 'Log Level',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'level'),
        valueLabel,
    };

    const triggerSource = {
        label: 'Execution Trigger',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'triggerSource'),
        valueLabel: (trigger: string) => getTriggerSourceLabel(trigger as TriggerSource),
    };

    const failedBlockId = {
        label: `Failed ${workflowType === WorkflowType.DataCheckin ? 'Step' : 'Block'}`,
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'failedBlockId'),
        valueLabel,
    };

    const executionId = {
        label: 'Execution',
        placeholder: 'Enter Execution Id',
        tooltip: 'Enter the Execution Id for which you want to find the relevant logs',
        type: FacetFilterType.Text,
        default: { id: defaultValue(get, 'executionId', false) },
    };

    const dateRange = {
        label: `${longRunningExecution ? 'Log' : 'Execution'} Date Range`,
        type: FacetFilterType.Datetime,
        default: {
            min: defaultValue(get, 'dateRangeMin', false),
            max: defaultValue(get, 'dateRangeMax', false),
        },
    };

    const executionHistoryFacets = ref(
        (longRunningExecution
            ? { level }
            : workflowType === WorkflowType.Analytics
            ? { status, triggerSource, failedBlockId }
            : { status, failedBlockId }) as Record<string, FacetFilter>,
    );
    const executionHistoryFilters = ref(
        longRunningExecution
            ? ({ dateRange } as Record<string, FacetFilter>)
            : ({ dateRange, executionId } as Record<string, FacetFilter>),
    );
    const textNumberFilters = ref({
        executionId: get('executionId') ? get('executionId') : null,
    });

    const reducedExecutionHistoryFacets = computed(() => reducedFacetsFilters(executionHistoryFacets));
    const reducedExecutionHistoryFilters = computed(() => reducedFacetsFilters(executionHistoryFilters));
    onChange(() => {
        executionHistoryFilters.value = {
            ...executionHistoryFilters.value,
            executionId: {
                label: 'Execution',
                placeholder: 'Enter Execution Id',
                tooltip: 'Enter the Execution Id for which you want to find the relevant logs',
                type: FacetFilterType.Text,
                default: { id: defaultValue(get, 'executionId', false) },
            },
        };
        textNumberFilters.value.executionId = defaultValue(get, 'executionId', false);
    });

    return {
        executionHistoryFacets,
        reducedExecutionHistoryFacets,
        executionHistoryFilters,
        reducedExecutionHistoryFilters,
        textNumberFilters,
    };
}
