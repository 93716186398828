



import { defineComponent, watch } from '@vue/composition-api';

export default defineComponent({
    name: 'AssetParquetStatusParameter',
    props: {
        assetStructureForTask: {
            type: Object,
            default: () => {},
        },
    },

    setup(props, { emit }) {
        watch(
            () => props.assetStructureForTask,
            (structure: any) => {
                if (structure?.parquetStatus) {
                    emit('change', { value: structure.parquetStatus });
                }
            },
        );
    },
});
