import { useAxios } from '@/app/composable';
import { computed, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { WorkflowAPI } from '../api';
import { Task, ValidationError, Workflow } from '../types';

interface ErrorMessage {
    major: boolean;
    message: string;
    taskId: string | null;
}

export function useValidator(workflow: Ref<Workflow>, taskMap: Ref<Map<string, Task>>, toastr: any) {
    const axiosRunner = useAxios(true);

    const workflowDags = ref<any>([]);
    const validationErrors = ref<ValidationError[]>([]);

    // Adds all downstream tasks of invalid tasks in the list
    const findDownstreamInvalidTaskIds = (pendingTasks: string[]): string[] => {
        let all: string[] = [];
        pendingTasks.forEach((t: string) => {
            const task: Task = taskMap.value.get(pendingTasks[0]) as Task;
            if (task) {
                if (!all.includes(t)) all.push(t);

                if (task.downstreamTaskIds.length > 0)
                    all = [...new Set([...all, ...findDownstreamInvalidTaskIds(task.downstreamTaskIds)])];
            }
        });
        return all;
    };

    /**
     * Invalid tasks is a list of task ids of tasks that are shown to the user as invalid
     * for whatever reason (major or otherwise)
     */
    const invalidTaskIds = computed(() =>
        R.isNil(validationErrors.value)
            ? []
            : validationErrors.value.reduce((acc: string[], validationError: ValidationError) => {
                  if (validationError.taskId) acc.push(validationError.taskId);
                  return acc;
              }, []),
    );

    /**
     * Blocked tasks is a list of task ids of tasks that are invalid in a major way
     * that should not allow dry run or test run
     * The blocked status propagates to all their subsequent tasks as well
     */
    const blockedTaskIds = computed(() =>
        findDownstreamInvalidTaskIds(
            R.isNil(validationErrors.value)
                ? []
                : validationErrors.value.reduce((acc: string[], validationError: ValidationError) => {
                      if (validationError.taskId && validationError.major) acc.push(validationError.taskId);
                      return acc;
                  }, []),
        ),
    );

    const isLocked = ref<boolean>(false);

    const checkCycles = (issues: any): ErrorMessage[] => {
        if (issues.cycles.length > 0) {
            return [
                {
                    major: true,
                    message: `The analytics pipeline has ${issues.cycles.length} ${
                        issues.cycles.length === 1 ? 'cycle' : 'cycles'
                    }`,
                    taskId: null,
                },
            ];
        }
        return [];
    };

    const checkDags = (issues: any): ErrorMessage[] => {
        if (issues.dags.length > 1) {
            return [
                {
                    major: true,
                    message: `The analytics pipeline has ${issues.dags.length} different chains. You can have a single chain only`,
                    taskId: null,
                },
            ];
        }
        return [];
    };

    const checkLoops = (issues: any): ErrorMessage[] => {
        const errors: ErrorMessage[] = [];
        if (Object.keys(issues.loops).length > 0) {
            Object.keys(issues.loops).forEach((loopId: string) => {
                const loopValidation: {
                    emptyLoop: boolean;
                    invalidBranches: string[];
                    invalidTasksInLoop: string[];
                    nestedLoopsFound: boolean;
                    tasksInLoop: string[];
                    invalidConnectingtasksOutsideLoop: string[];
                } = issues.loops[loopId];
                const loopTask = taskMap.value.get(loopId);
                if (loopValidation.emptyLoop) {
                    errors.push({
                        major: true,
                        message: `Loop '${loopTask?.displayName}' does not contain any tasks`,
                        taskId: loopId,
                    });
                }
                if (loopValidation.nestedLoopsFound) {
                    errors.push({
                        major: true,
                        message: `Nested loops found in loop '${loopTask?.displayName}'`,
                        taskId: loopId,
                    });
                }
                if (loopValidation.invalidBranches.length > 0) {
                    loopValidation.invalidBranches.forEach((branchId: string) => {
                        const branchTask = taskMap.value.get(branchId);
                        errors.push({
                            major: false,
                            message: `Loop '${loopTask?.displayName}' contains invalid branch ending in task ${branchTask?.displayName}`,
                            taskId: branchId,
                        });
                    });
                }

                if (loopValidation.invalidTasksInLoop.length > 0) {
                    loopValidation.invalidTasksInLoop.forEach((taskId: string) => {
                        const taskObj = taskMap.value.get(taskId);
                        errors.push({
                            major: true,
                            message: `Loop '${loopTask?.displayName}' task that cannot be included ${taskObj?.displayName}`,
                            taskId: taskId,
                        });
                    });
                }

                if (loopValidation.invalidConnectingtasksOutsideLoop.length > 0) {
                    loopValidation.invalidConnectingtasksOutsideLoop.forEach((taskId: string) => {
                        const taskObj = taskMap.value.get(taskId);
                        errors.push({
                            major: true,
                            message: `'${taskObj?.displayName} task cannot be connected to an intermediate task of loop ${loopTask?.displayName}' `,
                            taskId: taskId,
                        });
                    });
                }
            });
        }
        return errors;
    };

    const isDisabled = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.isDisabled) {
            const task: Task = taskMap.value.get(uuid) as Task;
            return [
                {
                    major: true,
                    message: `The block used in task '${task.displayName}' has been deprecated.`,
                    taskId: uuid,
                },
            ];
        }

        return [];
    };

    const isValid = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.isValid === false) {
            const task: Task = taskMap.value.get(uuid) as Task;
            return [
                {
                    major: true,
                    message: `Task '${task.displayName}' is missing required parameters`,
                    taskId: uuid,
                },
            ];
        }

        return [];
    };

    const hasModelError = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.modelError) {
            const task: Task = taskMap.value.get(uuid) as Task;
            const message = `The model used in '${task.displayName}' ${
                issues.modelError === 'deprecated' ? 'has been deprecated' : 'is not available anymore'
            }. Please update the specific task configuration.`;

            return [
                {
                    major: true,
                    message,
                    taskId: uuid,
                },
            ];
        }
        return [];
    };

    const canRunOnNewData = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.canRunOnNewData === false) {
            const task: Task = taskMap.value.get(uuid) as Task;
            return [
                {
                    major: true,
                    message: `The task '${task.displayName}' cannot run on a subset of the data. Please remove this task or update the input task to use the whole dataset (by setting as False the 'Run Only On New Data' option).`,
                    taskId: uuid,
                },
            ];
        }
        return [];
    };

    const assetIssues = (
        issues: { assets: { assetId: number; hasAccess: boolean; conflictingPermisions: boolean }[] },
        uuid: string,
    ): ErrorMessage[] => {
        const task: Task = taskMap.value.get(uuid) as Task;
        return issues.assets.reduce(
            (acc: ErrorMessage[], asset: { assetId: number; hasAccess: boolean; conflictingPermisions: boolean }) => {
                if (!asset.hasAccess)
                    acc.push({
                        major: true,
                        message: `The task '${task.displayName}' is using an asset which you do not have the permissions to use`,
                        taskId: uuid,
                    });
                if (asset.conflictingPermisions)
                    acc.push({
                        major: true,
                        message: `'${task.displayName}' is using an asset which some of the users that have access to the specific pipeline do not have the permissions to use`,
                        taskId: uuid,
                    });
                return acc;
            },
            [],
        );
    };

    const isTaskConnectedToInvalidLoop = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.isTaskConnectedToInvalidLoop === true) {
            const task: Task = taskMap.value.get(uuid) as Task;
            return [
                {
                    major: true,
                    message: `The task '${task.displayName}' cannot be executed while connected to an invalid for loop`,
                    taskId: uuid,
                },
            ];
        }
        return [];
    };

    const isLoopIterationsValid = (issues: any, uuid: string): ErrorMessage[] => {
        if (issues.isLoopIterationsValid === false) {
            const task: Task = taskMap.value.get(uuid) as Task;
            return [
                {
                    major: true,
                    message: `The maximum amount of iterations for loop '${task.displayName}' is 30, as it contains model training. `,
                    taskId: uuid,
                },
            ];
        }
        return [];
    };

    const runValidation = async () => {
        await axiosRunner
            .exec(WorkflowAPI.validate(workflow.value.id))
            .then((resValidation: any) => {
                validationErrors.value = [];
                if (!R.isNil(resValidation)) {
                    for (let e = 0; e < Object.keys(resValidation.data).length; e++) {
                        const uuid = Object.keys(resValidation.data)[e];
                        const issues = resValidation.data[uuid];
                        if (workflow.value.id === uuid) {
                            workflowDags.value = issues.dags;

                            validationErrors.value = [...validationErrors.value, ...checkCycles(issues)];
                            validationErrors.value = [...validationErrors.value, ...checkDags(issues)];
                            validationErrors.value = [...validationErrors.value, ...checkLoops(issues)];
                        } else if (taskMap.value.has(uuid)) {
                            validationErrors.value = [...validationErrors.value, ...isDisabled(issues, uuid)];
                            validationErrors.value = [...validationErrors.value, ...isValid(issues, uuid)];
                            validationErrors.value = [...validationErrors.value, ...hasModelError(issues, uuid)];
                            validationErrors.value = [...validationErrors.value, ...canRunOnNewData(issues, uuid)];
                            validationErrors.value = [
                                ...validationErrors.value,
                                ...isTaskConnectedToInvalidLoop(issues, uuid),
                            ];
                            validationErrors.value = [
                                ...validationErrors.value,
                                ...isLoopIterationsValid(issues, uuid),
                            ];
                            validationErrors.value = [...validationErrors.value, ...assetIssues(issues, uuid)];
                        }
                    }
                } else {
                    validationErrors.value.push({
                        major: true,
                        message: 'An error occurred while trying to validate',
                        taskId: null,
                    });
                    throw new Error('An error occurred while trying to validate');
                }
            })
            .catch((e: { response: { status: any } }) => {
                if (e.response && e.response.status === 403) {
                    isLocked.value = true;
                    toastr.w('The analytics pipeline is locked by another user', 'Warning');
                }
            });
    };

    return { validationErrors, invalidTaskIds, blockedTaskIds, workflowDags, isLocked, runValidation };
}
