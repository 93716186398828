import { S } from '@/app/utilities';
import { computed, ref, Ref } from '@vue/composition-api';
import compareVersions from 'compare-versions';
import * as R from 'ramda';
import { Block } from '../types';
import { useBlocks as useAvailableBlocks, useFuse } from '@/app/composable';

const filterBlock = (block: any, filter: string | undefined, framework: string | undefined, platform: string) => {
    if (R.isNil(filter) && R.isNil(framework) && R.isNil(platform)) return true;
    if (block.category === filter) {
        if (
            ((block.frameworks instanceof Array && block.frameworks.includes(framework)) ||
                block.frameworks === framework) &&
            (!S.has('platforms', block) ||
                (block.platforms instanceof Array && block.platforms.includes(platform)) ||
                block.platforms === platform)
        ) {
            return true;
        }
    }

    return false;
};
const byType = R.groupBy((block: any) => {
    return block.type ? block.type : 'other';
});

const sortByType = R.sortWith([R.ascend(R.prop('type'))]);
const sortByName = R.sortWith([R.ascend(R.prop('name'))]);

export function useBlocks(
    searchText?: Ref<string>,
    activeFilter?: Ref<string | undefined>,
    framework?: string | undefined,
    frameworkVersion?: Ref<string | undefined>,
    platform?: string | undefined,
) {
    const { blocks, loading, blocksMap, refetch } = useAvailableBlocks();

    const { result: filteredOptions } = useFuse<Block>(searchText || ref(''), blocks as any, {
        keys: ['name', 'description', 'type'],
        threshold: 0.2,
        ignoreLocation: true,
    });

    const visibleBlocks = computed(() => {
        let blocksClone = R.clone(filteredOptions.value);
        // blocksClone = R.filter(
        //     (b) => b.category === activeFilter.value && b.frameworks.includes(framework),
        //     blocksClone,
        // );

        blocksClone = R.filter(
            (b) => !R.isNil(platform) && filterBlock(b, activeFilter?.value || undefined, framework, platform),
            blocksClone,
        );
        blocksClone = R.filter((b: Block) => !b.id.startsWith('dc.'), blocksClone);

        // filter out the blocks that are not compatible with the current framework version
        blocksClone = R.filter(
            (b: Block) =>
                !!frameworkVersion &&
                !R.isNil(frameworkVersion.value) &&
                isVersionBetween(frameworkVersion.value, b.executionImageVersionFrom, b.executionImageVersionTo),
            blocksClone,
        );
        // keep only the latest version of a block if multiple versions of it are available
        blocksClone = R.uniqBy(
            (b: Block) => b.id,
            R.sortBy((b) => -b.version, blocksClone),
        );

        blocksClone = sortByName(sortByType(blocksClone as { name: string; type: string }[]) as Block[]) as Block[];
        let groupedBlocks = byType(blocksClone);

        /* if a block has no type, it is grouped in the 'other' type and
         * it is put last in the list if it occurs with other types
         */
        if ('other' in groupedBlocks && Object.keys(groupedBlocks).length > 1) {
            const keepOtherBlocks = R.clone(groupedBlocks.other);
            delete groupedBlocks.other;
            groupedBlocks = { ...groupedBlocks, other: keepOtherBlocks };
        }

        return groupedBlocks;
    });

    const isVersionBetween = (version: string, from: string, to: string): boolean => {
        return (
            version === 'latest' ||
            ((!from || compareVersions(from, version) <= 0) && (!to || compareVersions(to, version) >= 0))
        );
    };

    return { blocks, visibleBlocks, loading, blocksMap, refetch, filteredOptions };
}
