import { Ref, computed, ref } from '@vue/composition-api';
import { ScheduleAPI } from '../api';
import { ScheduleType, Workflow } from '../types';
import store from '@/app/store';
import { isNil, is } from 'ramda';

export function useWorkflowSchedules(initialize = true) {
    const schedules: Ref<ScheduleType[]> = computed(() =>
        !isNil(store.getters.pipelineDesigner.schedules)
            ? (store.getters.pipelineDesigner.schedules as ScheduleType[])
            : [],
    );
    const saving = ref<boolean>(false);
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingSchedules);

    const refetch = async (force = true) => {
        await store.dispatch.pipelineDesigner.loadSchedules(force);
    };

    const save = async (schedule: any): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            if (schedule.id) {
                saving.value = true;
                ScheduleAPI.update(schedule.id, schedule)
                    .then(() => {
                        refetch();
                        resolve('Schedule has been updated successfully');
                    })
                    .catch((e) => {
                        reject(e.response.data.message);
                    })
                    .finally(() => (saving.value = false));
            } else {
                saving.value = true;
                ScheduleAPI.create([schedule])
                    .then(() => {
                        refetch();
                        resolve('Schedule has been created successfully');
                    })
                    .catch((e) => {
                        reject(e.response.data.message);
                    })
                    .finally(() => (saving.value = false));
            }
        });
    };

    const changeStatus = (schedule: ScheduleType): Promise<void> => {
        const idx = schedules.value.findIndex((obj: ScheduleType) => obj.id === schedule.id);
        return new Promise((resolve, reject) => {
            if (!schedule.id) reject('No id in schedule!');
            else if (schedule.isEnabled) {
                saving.value = true;
                ScheduleAPI.deactivate(schedule.id)
                    .then(() => {
                        schedules.value[idx].isEnabled = false;
                        resolve();
                    })
                    .catch((e) => reject(e.response.data.message))
                    .finally(() => (saving.value = false));
            } else {
                saving.value = true;
                ScheduleAPI.activate(schedule.id)
                    .then(() => {
                        schedules.value[idx].isEnabled = true;
                        resolve();
                    })
                    .catch((e) => reject(e.response.data.message))
                    .finally(() => (saving.value = false));
            }
        });
    };

    const deleteSchedule = (deleteThis: string | ScheduleType): Promise<void> => {
        const id = is(String, deleteThis) ? deleteThis : ((deleteThis as ScheduleType).id as string);
        saving.value = true;
        return new Promise((resolve, reject) => {
            ScheduleAPI.delete(id)
                .then(() => {
                    const idx = schedules.value.findIndex((obj: any) => obj.id === id);
                    schedules.value.splice(idx, 1);
                    resolve();
                })
                .catch((e) => reject(e.response.data.message))
                .finally(() => (saving.value = false));
        });
    };

    if (initialize) refetch(false);

    return {
        schedules,
        save,
        loading,
        changeStatus,
        deleteSchedule,
        saving,
        refetch,
    };
}
