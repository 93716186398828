

































import { TwSelect } from '@/app/components';
import { S } from '@/app/utilities';
import { useDynamicColumnParameter, useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter } from '@/modules/workflow-designer/types';
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'DynamicColumnParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        workflowConfiguration: {
            type: Object,
            default: () => {
                return {};
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: { TwSelect, ParameterValidation },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const parameter = ref<any>(props.parameter);
        const dataframes = ref<any>(props.dataframes);
        const workflowConfiguration = ref<any>(props.workflowConfiguration);
        const columnsPerTask = ref<any>(props.columnsPerTask);

        const findInitialValue = (incomingValue: any) => {
            let resultingValue = null;
            if (incomingValue && S.has('value', incomingValue) && !R.isNil(incomingValue.value)) {
                // Case of an existing value already defined
                resultingValue = incomingValue.value;
            } else if (S.has('default', props.parameter.validation)) {
                // Case of no value defined but default is available
                resultingValue = props.parameter.validation.default;
            }
            return resultingValue;
        };

        const { columns } = useDynamicColumnParameter(
            parameter as Ref<InputParameter>,
            workflowConfiguration,
            dataframes,
            columnsPerTask,
        );

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            findInitialValue,

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', { value: currentValue.value, ref: props.parameter.validation.ref });
                }
            },
        );

        // Set the initial value on load
        change();

        watch(
            () => columns.value,
            (incoming: any, old: any) => {
                if (old && !R.isEmpty(old) && !R.equals(old, incoming)) {
                    const newColumns = incoming.map((col: any) => col.value);
                    if (props.parameter.multiple) {
                        currentValue.value = currentValue.value.filter((col: string) => newColumns.includes(col));
                        change();
                    } else if (!R.isNil(currentValue.value) && !newColumns.includes(currentValue.value)) {
                        currentValue.value = findInitialValue(null);
                        change();
                    }
                }
            },
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        return { currentValue, change, columns, changeDate };
    },
});
