






















import * as R from 'ramda';
import { computed, defineComponent, Ref, watch } from '@vue/composition-api';
import { DateTimePicker } from '@/app/components';
import { InputParameter } from '@/modules/workflow-designer/types';
import { useParameter } from '@/modules/workflow-designer/composable';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'DateTimeParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: { ParameterValidation, DateTimePicker },
    setup(props, { emit }) {
        const validationValue = computed<any>(() =>
            R.isEmpty(value.value) || currentValue.value === null ? null : currentValue.value,
        );
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);

        const { currentValue, change, changeDate } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                return !R.isNil(incomingValue) && !R.isEmpty(incomingValue) ? incomingValue.value : null;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    emit('change', { value: currentValue.value });
                }
            },
        );
        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );
        change();

        return { currentValue, change, changeDate, validationValue };
    },
});
