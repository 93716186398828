import { S } from '@/app/utilities';
import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { DataType, OtherInputParameterType } from '../../constants';
import { InputParameter } from '../../types';

export function useColumnParameter(
    parameter: Ref<InputParameter>,
    dataframes: Ref<any>,
    columnsPerTask: Ref<any>,
    val: Ref<any>,
) {
    // Finds the component value based on incoming value and defaults
    const allColumns = computed(() => {
        if (
            !R.isNil(parameter.value.validation) &&
            !R.isNil(parameter.value.validation.ref) &&
            !R.isEmpty(parameter.value.validation.ref) &&
            S.has(parameter.value.validation.ref as string, dataframes.value) &&
            S.has('task', dataframes.value[parameter.value.validation.ref as string]) &&
            S.has(dataframes.value[parameter.value.validation.ref as string].task, columnsPerTask.value)
        ) {
            return columnsPerTask.value[dataframes.value[parameter.value.validation.ref].task];
        }
        return null;
    });

    // Calculates column types to include (if list is empty then include all)
    const columnTypes = computed(() => {
        const types = [];
        if (!R.isNil(allColumns.value) && !R.isNil(parameter.value.type)) {
            if (parameter.value.type === OtherInputParameterType.Same) {
                return R.isNil(val.value) || R.isEmpty(val.value)
                    ? []
                    : val.value.reduce((acc: DataType[], paramKey: string) => {
                          const key = allColumns.value[paramKey];
                          if (key) acc.push(key);
                          return acc;
                      }, []);
            }
            const typeStrings = R.split(/\s*\|\|\s*/, parameter.value.type);

            for (let t = 0; t < typeStrings.length; t++) {
                const typeString = typeStrings[t];
                if (Object.values(DataType).includes(typeString as DataType)) {
                    types.push(typeString);
                } else if (Object.values(OtherInputParameterType).includes(typeString as OtherInputParameterType)) {
                    types.push(typeString);
                } else {
                    throw new Error(`Type '${typeString}' is not a valid DataType or OtherInputParameterType`);
                }
            }
        }

        return types;
    });

    // Calculates which columns to show in the dropdown and filters
    // by column type/types if any defined
    const columns = computed(() => {
        if (R.isNil(allColumns.value)) {
            return null;
        }
        return Object.keys(allColumns.value).reduce(
            (acc: { label: string; value: string; selectable: boolean; type: string }[], column: string) => {
                const columnType = allColumns.value[column];
                acc.push({
                    label: column,
                    value: column,
                    selectable: columnTypes.value.length === 0 || columnTypes.value.includes(columnType),
                    type: columnType,
                });
                return acc;
            },
            [],
        );
    });

    return { columns, columnTypes };
}
