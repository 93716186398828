import store from '@/app/store';
import { Ref, computed, watch } from '@vue/composition-api';
import { ExecutionLog } from '../types';
import { is, isNil } from 'ramda';

const onLogsChangeCallbacks: Function[] = [];

export function useExecutionLogs(inputIds: Ref<string[]>, immediate = true) {
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingExecutionLogs);

    const executionIds = computed(() => {
        if (isNil(inputIds.value)) return [];
        return is(Array, inputIds.value) ? inputIds.value : [inputIds.value];
    });

    const executionLogsMap = computed(() => store.getters.pipelineDesigner.executionLogsMap);

    const executionLogs = (id: string) => executionLogsMap.value[id] || [];

    const errorLogs = (id: string) => executionLogs(id).filter((log: ExecutionLog) => log.level === 'error');

    const fetch = async (ids: string[], force = false) => {
        const idsToFetch = force ? ids : ids.filter((id: string) => !Object.keys(executionLogsMap.value).includes(id));
        if (idsToFetch.length === 0) return;
        await store.dispatch.pipelineDesigner.loadExecutionLogs(idsToFetch);
    };

    const refresh = (id?: string) => fetch(isNil(id) ? inputIds.value : [id], true);

    const onLogsChange = (callback: Function) => {
        onLogsChangeCallbacks.push(callback);
    };

    watch(
        () => executionIds.value,
        async (newExecutionIds: string[]) => {
            await fetch(newExecutionIds);
        },
        { immediate },
    );
    watch(
        () => executionLogsMap.value,
        () => {
            for (let c = 0; c < onLogsChangeCallbacks.length; c++) {
                const callback = onLogsChangeCallbacks[c];
                callback();
            }
        },
        { deep: true, immediate: true },
    );

    return { executionLogs, errorLogs, loading, onLogsChange, refresh };
}
