import { useAxios } from '@/app/composable';
import { Ref, computed, ref } from '@vue/composition-api';
import { WorkflowAPI } from '../api';
import { Workflow, WorkflowTrigger } from '../types';
import { isNil } from 'ramda';
import store from '@/app/store';

export function useWorkflowTriggers(workflow: Ref<Workflow>, initialize = true) {
    const { exec, loading: saving } = useAxios(true);
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingTriggers);

    const triggers = computed((): readonly WorkflowTrigger[] => store.getters.pipelineDesigner.triggers || []);

    const hasAccessToTriggers = computed(() =>
        triggers.value.flatMap((trigger) => trigger.conditions).every((condition) => condition.hasAccess),
    );

    const hasInvalidInputAssetInTriggers = computed(() =>
        triggers.value.flatMap((trigger) => trigger.conditions).some((condition) => condition.hasInvalidInputAsset),
    );

    const save = async (trigger: any) => {
        return new Promise<string>((resolve, reject) => {
            if (trigger.id) {
                exec(WorkflowAPI.updateTrigger(workflow.value.id, trigger.id, trigger))
                    .then(() => {
                        refetch();
                        resolve('Trigger has been updated successfully');
                    })
                    .catch((e) => reject(e.response.data.message));
            } else {
                exec(WorkflowAPI.createTrigger(workflow.value.id, trigger))
                    .then(() => {
                        refetch();
                        resolve('Trigger has been created successfully');
                    })
                    .catch((e) => reject(e.response.data.message));
            }
        });
    };

    const deleteTrigger = (id: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            exec(WorkflowAPI.deleteTrigger(workflow.value.id, id))
                .then(async () => {
                    await refetch();
                    resolve();
                })
                .catch((e) => {
                    reject(e.response.data.message);
                });
        });
    };

    const changeStatus = (trigger: WorkflowTrigger): Promise<void> => {
        const idx = triggers.value.findIndex((obj: WorkflowTrigger) => obj.id === trigger.id);
        return new Promise((resolve, reject) => {
            exec(WorkflowAPI.toggleTrigger(workflow.value.id, trigger.id))
                .then((res: any) => (triggers.value[idx].isEnabled = res.data.isEnabled))
                .catch((e) => {
                    reject(e.response.data.message);
                });
        });
    };

    const refetch = async (force = true) => {
        await store.dispatch.pipelineDesigner.loadTriggers(force);
    };

    if (initialize) refetch(false);

    return {
        triggers,
        loading,
        save,
        deleteTrigger,
        changeStatus,
        refetch,
        hasAccessToTriggers,
        hasInvalidInputAssetInTriggers,
        saving,
    };
}
