import { useVariables } from '@/app/composable';
import { VariableType } from '@/app/constants';
import { Variable } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { computed, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { DataType, OtherInputParameterType } from '../../constants';
import { InputParameter } from '../../types';

export function useLogicalParameter(
    parameter: Ref<InputParameter>,
    val: Ref<any>,
    availableVariables: Ref<Record<string, Variable>>,
) {
    const viewClause = (
        clause: any,
        displayNullParameters: { param: string; if: { param: string; value: any } | null }[] = [],
    ) => {
        return parameter.value.parameters
            .reduce((acc: string[], param: InputParameter) => {
                if (
                    S.has(param.name, clause) &&
                    !R.isNil(clause[param.name]) &&
                    !R.isEmpty(clause[param.name]) &&
                    S.has('value', clause[param.name]) &&
                    !R.isNil(clause[param.name].value) &&
                    !R.isEmpty(clause[param.name].value)
                ) {
                    let text = S.sanitizeHtml(clause[param.name].value);
                    if (param.validation && S.has('values', param.validation)) {
                        for (
                            let i = 0;
                            param.validation && param.validation?.values && i < param.validation?.values.length;
                            i++
                        ) {
                            const item = param.validation?.values[i];
                            if (R.is(Object, item) && S.has('text', item) && item.value === clause[param.name].value) {
                                text = S.sanitizeHtml(item.text);
                            }
                        }
                    } else {
                        const variableType = ref(getVariableType(parameter.value.type));
                        const input = ref(text);
                        const { displayValue } = useVariables(input, variableType, availableVariables);
                        text = displayValue.value; // value already sanitized
                    }
                    acc.push(text);
                } else {
                    // for some special parameters we want to show the actual null value
                    const displayNullParameter = displayNullParameters.find(
                        (nullParam: { param: string; if: { param: string; value: any } | null }) =>
                            nullParam.param === param.name,
                    );
                    // display <null> if null param is in 'displayNullParameters' and has no condition or
                    // has a valid condition (the 'param' in 'if' condition exists in 'clause' and has the
                    // same 'value' as the 'value' in 'if' condition)
                    if (
                        displayNullParameter &&
                        (R.isNil(displayNullParameter.if) ||
                            (S.has(displayNullParameter.if.param, clause) &&
                                clause[displayNullParameter.if.param] &&
                                S.has('value', clause[displayNullParameter.if.param]) &&
                                clause[displayNullParameter.if.param].value === displayNullParameter.if.value))
                    )
                        acc.push('<span class="italic text-neutral-600">&#60;null&#62;</span>');
                }
                return acc;
            }, [])
            .join(' ');
    };

    const getVariableType = (t: DataType | OtherInputParameterType | null): VariableType => {
        if (t === DataType.Double) return VariableType.Double;
        if (t === DataType.Integer) return VariableType.Integer;

        return VariableType.String;
    };

    const newConditionTemplate = computed(() => {
        return parameter.value.parameters.reduce((acc: any, param: InputParameter) => {
            const newAcc = { ...acc };
            newAcc[param.name] = null;
            return newAcc;
        }, {});
    });
    const hasValue = computed(
        () =>
            !R.isNil(val.value) &&
            !R.isEmpty(val.value) &&
            !R.isNil(val.value.clauses) &&
            !R.isEmpty(val.value.clauses),
    );

    const newGroupTemplate = { logical: 'AND', clauses: [] };

    const updateGroups = (query: any, newGroups: any[]) => {
        return {
            ...query,
            clauses: [...query.clauses, ...newGroups],
        };
    };

    const updateConditions = (query: any, newConditions: any[], replace = false) => {
        if (replace) {
            return {
                ...query,
                clauses: newConditions,
            };
        }
        return {
            ...query,
            clauses: [...query.clauses, ...newConditions],
        };
    };

    const isConditionCalculator = (condition: any) => {
        return !S.has('logical', condition) && !S.has('clauses', condition);
    };

    const isGroupCalculator = (group: any) => {
        return S.has('logical', group) && S.has('clauses', group);
    };

    const snakeCase = (str: string) => {
        const trimmedName = str.split('_').join(' ');

        return trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);
    };

    const preprocessCondition = (condition: any) => {
        return condition;
    };

    const conditionsExtractor = (query: any) => {
        return S.has('clauses', query) && S.has('logical', query) && !R.isNil(query.clauses) ? query.clauses : [];
    };

    const themeCalculator = (query: any) => {
        switch (query.logical) {
            case 'AND':
                return 'purple';
            case 'OR':
                return 'teal';
            default:
                return null;
        }
    };

    const complexParameterIncomingProcessor = (incomingValue: any) => {
        return incomingValue;
    };

    const complexParameterOutgoingProcessor = (outgoingValue: any) => {
        return outgoingValue;
    };

    return {
        newConditionTemplate,
        updateConditions,
        updateGroups,
        preprocessCondition,
        isConditionCalculator,
        isGroupCalculator,
        snakeCase,
        newGroupTemplate,
        conditionsExtractor,
        themeCalculator,
        complexParameterIncomingProcessor,
        complexParameterOutgoingProcessor,
        hasValue,
        viewClause,
    };
}
