import { Ref, computed } from '@vue/composition-api';
import { ColumnChangeStatus, ExecutionDataType } from '../constants';
import * as R from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ColumnChanges, ColumnDataTypeChanges, ResultCompleteness, ResultCompletenessResult } from '../types';
dayjs.extend(utc);

export function useResultCompleteness(completeness: Ref<ResultCompleteness | undefined>) {
    const columnChangesSeries = computed(() =>
        completeness.value?.results.map((row: ResultCompletenessResult) => {
            return row.columnChanges.reduce(
                (
                    acc: {
                        series: { name: string; data: number[] }[];
                        labels: string[];
                    },
                    change: ColumnChanges | undefined,
                ) => {
                    if (R.isNil(change)) {
                        acc.series[0].data.push(0);
                        acc.series[1].data.push(0);
                        acc.labels.push('');
                        return acc;
                    }

                    acc.labels.push(dayjs.utc(change.timestamp).format('DD MMM'));
                    acc.series[0].data.push(
                        change.columns.filter(
                            (column: { name: string; dataType: ExecutionDataType; status: ColumnChangeStatus }) =>
                                column.status === ColumnChangeStatus.Existing,
                        ).length,
                    );
                    acc.series[1].data.push(
                        change.columns.filter(
                            (column: { name: string; dataType: ExecutionDataType; status: ColumnChangeStatus }) =>
                                column.status === ColumnChangeStatus.New,
                        ).length,
                    );

                    return acc;
                },
                {
                    series: [
                        { name: 'Existing Columns', data: [] },
                        { name: 'New Columns', data: [] },
                    ],
                    labels: [],
                },
            );
        }),
    );
    const columnTypeChangesSeries = computed(() =>
        completeness.value?.results.map((row: ResultCompletenessResult) => {
            return row.columnDataTypeChanges.reduce(
                (
                    acc: {
                        series: { name: string; data: number[] }[];
                        labels: string[];
                    },
                    change: ColumnDataTypeChanges | undefined,
                    index: number,
                ) => {
                    if (R.isNil(change)) {
                        acc.series[0].data.push(0);
                        acc.labels.push('');
                        return acc;
                    }
                    acc.labels.push(dayjs.utc(change.timestamp).format('DD MMM'));
                    acc.series[0].data.push(change.columns.length);

                    return acc;
                },
                {
                    series: [{ name: 'Columns with changed type', data: [] }],
                    labels: [],
                },
            );
        }),
    );

    const calculateTypeChanges = (data: ColumnDataTypeChanges | undefined) =>
        !R.isNil(data)
            ? data.columns.reduce(
                  (acc: any, column: { name: string; dataType: ExecutionDataType; oldDataType: ExecutionDataType }) => {
                      const key = `${column.dataType}->${column.oldDataType}`;
                      if (!R.has(key, acc)) acc[key] = 0;
                      acc[key] += 1;
                      return acc;
                  },
                  {},
              )
            : {};

    const calculateColumnChanges = (data: ColumnChanges | undefined) =>
        !R.isNil(data)
            ? data.columns.reduce(
                  (
                      acc: { existing: number; removed: number; new: number },
                      column: { dataType: ExecutionDataType; name: string; status: ColumnChangeStatus },
                  ) => {
                      if (column.status === ColumnChangeStatus.Existing) acc.existing += 1;
                      else if (column.status === ColumnChangeStatus.New) acc.new += 1;
                      else if (column.status === ColumnChangeStatus.Removed) acc.removed += 1;
                      return acc;
                  },
                  { existing: 0, removed: 0, new: 0 },
              )
            : { existing: 0, removed: 0, new: 0 };

    const columnChangesTooltipFormatter = (details: {
        ctx: { el: any };
        dataPointIndex: number;
        seriesIndex: number;
        series: number[][];
    }) => {
        if (!completeness.value) return '';
        const row = Number(details.ctx.el.attributes['data-identifier'].value);

        const data: ColumnChanges | undefined = completeness.value.results[row].columnChanges[details.dataPointIndex];
        const columnsPerStatus = calculateColumnChanges(data);
        let tooltip = '<div class="flex flex-col p-2 space-y-1">';
        tooltip += `<div><span class="font-semibold">${dayjs
            .utc(data.timestamp)
            .format('DD-MM-YYYY HH:mm:ss')}</span></div>`;
        tooltip += `<div class=" text-xs ">`;

        tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span>Existing Columns:</span>
                <span class="px-1 capitalize rounded-sm text-primary-600">${columnsPerStatus.existing}</span>
                </span>`;
        tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span>New Columns:</span>
                <span class="px-1 capitalize rounded-sm text-secondary-600">${columnsPerStatus.new}</span>
                </span>`;
        tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span>Removed Columns:</span>
                <span class="px-1 text-red-600 capitalize rounded-sm">${columnsPerStatus.removed}</span>
                </span>`;
        tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span class="font-bold">Total Columns:</span>
                <span class="px-1 text-white capitalize bg-green-600 rounded-sm">${
                    columnsPerStatus.existing + columnsPerStatus.new
                }</span>
                </span>`;
        tooltip += '</div>';

        return tooltip + '</div>';
    };

    const columnTypeChangesTooltipFormatter = (details: {
        ctx: { el: any };
        dataPointIndex: number;
        seriesIndex: number;
        series: number[][];
    }) => {
        if (!completeness.value) return '';
        const row = Number(details.ctx.el.attributes['data-identifier'].value);
        const data: ColumnDataTypeChanges =
            completeness.value.results[row].columnDataTypeChanges[details.dataPointIndex];
        const typeChanges = calculateTypeChanges(data);

        let tooltip = '<div class="flex flex-col p-2 space-y-1">';
        tooltip += `<div><span class="font-semibold">${dayjs
            .utc(data.timestamp)
            .format('DD-MM-YYYY HH:mm:ss')}</span></div>`;
        tooltip += `<div class="space-y-0.5 text-xs ">`;
        for (let c = 0; c < Object.keys(typeChanges).length && c < 2; c++) {
            const key = Object.keys(typeChanges)[c];
            tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span>${key}:</span>
                <span class="px-1 capitalize rounded-sm text-primary-600">${typeChanges[key]}</span>
                </span>`;
        }
        if (Object.keys(typeChanges).length > 2)
            tooltip += `<span class="italic text-neutral-600">and ${
                Object.keys(typeChanges).length - 2
            } more combinations</span>`;

        tooltip += `<span class="flex flex-row items-center justify-between space-x-1">
                <span class="font-bold">Total type changes:</span>
                <span class="px-1 text-white capitalize bg-green-600 rounded-sm">${data.columns.length}</span>
                </span>`;
        tooltip += '</div>';

        return tooltip + '</div>';
    };

    return {
        columnChangesSeries,
        columnTypeChangesSeries,
        columnChangesTooltipFormatter,
        columnTypeChangesTooltipFormatter,
        calculateColumnChanges,
        calculateTypeChanges,
    };
}
